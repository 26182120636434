<a href="#" [routerLink]="cardData.redirectTo">
  <div class="card card-style">
    <div class="card-body p-3">
      <div class="d-flex">
        <div>
          <i class="fa fs-13 me-2" [class]="cardData.iconClass"></i>
        </div>
        <h4 class="card-title mb-1 fs-13 flex-fill">
          {{ cardData.cardTitle }}
        </h4>
      </div>
      <p class="card-text fs-12 op-0-8">
        {{ cardData.description }}
      </p>
    </div>
  </div>
</a>
