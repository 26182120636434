<div class="mb-5">
  <div class="pb-3">
    <div class="d-flex">
      <h3 class="card-title mb-0">Somatus Care Team</h3>
    </div>
  </div>
  <div *ngIf="!errorMessage">
    <ul class="list-group blog-autors">
      <span *ngIf="showLoading">
        <li class="border-top py-2">
          <div class="media d-flex">
            <div class="skeleton-loader__media-sm my-1 me-3">
              <span class="skeleton-loader"></span>
            </div>
            <div class="flex-fill">
              <div class="my-3"><span class="skeleton-loader"></span></div>
              <div class="my-3"><span class="skeleton-loader w-75"></span></div>
            </div>
          </div>
        </li>
        <li class="border-top py-2">
          <div class="media d-flex">
            <div class="skeleton-loader__media-sm my-1 me-3">
              <span class="skeleton-loader"></span>
            </div>
            <div class="flex-fill">
              <div class="my-3"><span class="skeleton-loader"></span></div>
              <div class="my-3"><span class="skeleton-loader w-75"></span></div>
            </div>
          </div>
        </li>
        <li class="border-top py-2">
          <div class="media d-flex">
            <div class="skeleton-loader__media-sm my-1 me-3">
              <span class="skeleton-loader"></span>
            </div>
            <div class="flex-fill">
              <div class="my-3"><span class="skeleton-loader"></span></div>
              <div class="my-3"><span class="skeleton-loader w-75"></span></div>
            </div>
          </div>
        </li>
        <li class="border-top py-2">
          <div class="media d-flex">
            <div class="skeleton-loader__media-sm my-1 me-3">
              <span class="skeleton-loader"></span>
            </div>
            <div class="flex-fill">
              <div class="my-3"><span class="skeleton-loader"></span></div>
              <div class="my-3"><span class="skeleton-loader w-75"></span></div>
            </div>
          </div>
        </li>
        <li class="border-top py-2">
          <div class="media d-flex">
            <div class="skeleton-loader__media-sm my-1 me-3">
              <span class="skeleton-loader"></span>
            </div>
            <div class="flex-fill">
              <div class="my-3"><span class="skeleton-loader"></span></div>
              <div class="my-3"><span class="skeleton-loader w-75"></span></div>
            </div>
          </div>
        </li>
      </span>
      <li
        class="border-top p-2"
        *ngFor="let member of careTeamList"
      >
        <div class="text-dark">
          <div class="media mt-0 d-sm-flex d-block">
            <img
              *ngIf="member.imageSrc"
              class="avatar-md rounded-circle me-3 my-auto"
              [src]="member.imageSrc"
              alt="Image description"
            />
            <div class="media-body">
                <div class="mt-1">
                  <h5 class="mb-0">{{ member.name }}</h5>
                  <p class="mb-1 text-13 text-primary">
                    {{ member.designation }}
                  </p>
                  <div class="row g-2" *ngIf="member.email || member.mobileNumber">
                    <div class="col-7 d-flex minw-0">
                      <a *ngIf="member.email" class="text-truncate a-link a-link-sm text-secondary is-reverse" href="mailto:{{member.email}}"><i class="fa fa-envelope-square me-1 fs-16 align-middle text-primary"></i> {{member.email}}</a>
                    </div>
                    <div class="col-5 d-flex minw-0 justify-content-end">
                      <a *ngIf="member.mobileNumber" class="text-truncate a-link a-link-sm text-secondary is-reverse" href="tel:{{member.mobileNumber}}"><i class="fa fa-phone-square me-1 fs-16 align-text-top text-primary"></i> {{member.mobileNumber}}</a>
                    </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="errorMessage">
    <div class="alert alert-danger d-flex fade show"
      role="alert">
      <div><i class="fa fa-warning me-2" aria-hidden="true"></i></div>
      <div class="flex-fill">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</div>
