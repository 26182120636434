<div class="card mb-5 p-5 text-center">
  <div class="mb-4">
    <img
      class="wrap-figure__img"
      src="../../../assets/images/no-data.png"
      alt="No data found"
    />
  </div>
  <h2 class="fw-bold">No data found</h2>
  <div class="text-gray mb-6">
    We couldn't find any data matching your search criteria. Please consider
    adjusting your filters or try again at a later time.
  </div>
  <div>
    <a
      href="javascript: void(0);"
      (click)="reloadPage()"
      class="btn btn-outline-danger mx-1 px-5"
      ><i class="fa fa-mail-reply me-1"></i> Retry</a
    >
  </div>
</div>
