import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/api-client';
import { InteractionService } from 'src/app/shared/services/patient.interaction.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { GridModel } from 'src/app/interfaces/grid.model';
import { FilterModel } from 'src/app/interfaces/filter.model';
import { Messages } from 'src/app/shared/common-constants/messages';
import { ICustomLogEvents, ISessionDetails } from 'src/app/interfaces/customLogEvents';
import { LoggingService } from 'src/app/services/logging.service';
declare const $: any;
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  iterationCount: number = 10;
  selectedPractice: any;
  userInfo: any;
  message: any = Messages;
  private _subscriptions = new Subscription();
  errorMsg: any = "";
  showLoading: boolean = false;
  gridData: GridModel = {
    items: [],
    pagingModel:{ 
      pageSize: environment.pageSize,
      totalRecords:0,
      currentPage: 1,
      totalPages: 0
    }
  }
  list: any = [];

  filterModel: FilterModel = {
    currentPage: 1,
    pageSize: environment.pageSize,
    patientFilter: {
      searchKey: '',
      stage: [],
      riskCategory: '',
      careMember: '',
      status: [],
      assignment: [],
      discharge: [],
      SortDirection: 'asc',
      SortBy: 'patientname',
      nephrologistName: ''
    }
  };
  attributePatients: any = {
    totalPatients: 0,
    display: true
  }
  totalPatients: number = 0;

  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = 'Patients List - Web';

  @Output() actionHandler: EventEmitter<any> = new EventEmitter();
  constructor(private _patientService: PatientService,
    private loggingService: LoggingService,
    private _interactionService: InteractionService) { }

  ngOnInit(): void {
    this.loggingService.eventService.selectedPracticeSubscription().subscribe((practice) => {
      this.selectedPractice = practice;
    });
    this.loggingService.storeService.userInfoSubscription().subscribe((info) => {
      this.userInfo = info;
    });
    this.sessionDetails = this.loggingService.getSessionDetails(this.userInfo, this.selectedPractice);;
    this.loggingService.logEventForComponentRendering(this.customEvent, this.sessionDetails);
    this.bindPatientList();
    let sub = this._interactionService.getpatientFilter$.subscribe((model) => 
    {
      this.filterModel=model;
      this.filterModel.currentPage = 1;

      if(this.filterModel.patientFilter.assignment?.length == 2){
        let assignmentDate =this.filterModel.patientFilter.assignment;
        this.filterModel.patientFilter.assignment[0] = this.GetDateWithOutTimeZone(assignmentDate[0]);
        this.filterModel.patientFilter.assignment[1] = this.GetDateWithOutTimeZone(assignmentDate[1]);
      }
      if(this.filterModel.patientFilter.discharge?.length == 2){
        let discharge =this.filterModel.patientFilter.discharge;
        this.filterModel.patientFilter.discharge[0] =this.GetDateWithOutTimeZone(discharge[0]);
        this.filterModel.patientFilter.discharge[1] = this.GetDateWithOutTimeZone(discharge[1]);
      }
      this.bindPatientList();
     });
    this._subscriptions.add(sub);
    this.getPatientCount();
  }
  private getPatientCount() {
    this.customLog = this.loggingService.createCustomLog('Patient Count API', '', 'INFO', this.sessionDetails, '/api/Patient/count', 'GET', {});
    this._patientService.apiPatientCountGet().subscribe((response: any) => {
      this.attributePatients = {
        ...this.attributePatients,
        totalPatients: response.totalPatient.count
      };
      this.customLog.message = 'API Call Successful';
      this.loggingService.trackEvent(this.customEvent, this.customLog);
    });
  }
 public GetDateWithOutTimeZone(date :Date)
 {
   return new Date(date.getTime() +  Math.abs(date.getTimezoneOffset()*60000) );
 }
  public async bindPatientList() {
    this.customLog = this.loggingService.createCustomLog('Patients List API', '', 'INFO', this.sessionDetails, '/api/Patient/list', 'POST', this.filterModel);
    try {
      this.showLoading = true;
      var result = await this._patientService.apiPatientListPost(this.filterModel).toPromise();
      this.list = result?.data;
      this.gridData.items = result?.data;
      this.gridData.pagingModel = result?.pagingModel;
      this.showLoading = false;
      this.customLog.message = 'API Call Successful';
      this.loggingService.trackEvent(this.customEvent, this.customLog);
    } 
    catch (ex: any) {
      this.errorMsg = ex.error?.message?.message;
      this.showLoading = false;
      this.customLog.level = 'ERROR';
      this.customLog.message = ex.error?.message?.message;
      this.loggingService.trackEvent(this.customEvent, this.customLog);
      this.loggingService.logException(ex);
    }
  }
  public gotoPage(page: number): void {
    this.filterModel.currentPage = page;
    this.bindPatientList();
  }
  getFormatDate(dob:Date){
   // return moment(dob).format('MM/DD/YYYY');
   const date = moment(dob);
   return date.isValid() ? date.format('MM/DD/YYYY') : '';
  }
  getAge(dob:any){
    return moment().diff(dob, 'years');
  }
  patientHandler(detail: any, type: string) {
    this.actionHandler.emit({
      detail: detail,
      actionType: type
    });
  }
  applySortPatient(columnName: string) {
    const prevSortBy = this.filterModel.patientFilter.SortBy;
    if(prevSortBy === columnName && this.filterModel.patientFilter.SortDirection === '') {
      this.filterModel.patientFilter.SortDirection = 'asc';
    } else {
      this.filterModel.patientFilter.SortDirection = '';
    }
    this.filterModel.patientFilter.SortBy = columnName;
    this.bindPatientList();
  }
  applySort(columnName: string) {
    const prevSortBy = this.filterModel.patientFilter.SortBy;
    if(prevSortBy === columnName && this.filterModel.patientFilter.SortDirection === '') {
      this.filterModel.patientFilter.SortDirection = 'asc';
    } else {
      this.filterModel.patientFilter.SortDirection = '';
    }
    this.filterModel.patientFilter.SortBy = columnName;
    this.bindPatientList();
  }
  renderArrowIcon(columnName: string) {
    if(columnName === this.filterModel.patientFilter.SortBy && this.filterModel.patientFilter.SortDirection === 'asc') {
      return 'table-header-asc';
    } else if(columnName === this.filterModel.patientFilter.SortBy) {
      return 'table-header-desc';
    }
    return '';
  }
  getIterations(): number[] {
    return new Array(this.iterationCount);
  }
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
