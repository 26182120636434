<div class="table-responsive">
  <table
    id="data-table"
    class="table text-nowrap m-0 table-bordered table-striped"
  >
    <thead class="table-head" *ngIf="list.length > 0 && !showLoading">
      <tr class="text-secondary bg-gray-200">
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          [ngClass]="renderArrowIcon('EnrollmentNo')"
          (click)="applySort('EnrollmentNo')">
          MEMBER ID
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          (click)="applySortPatient('patientname')"
          [ngClass]="renderArrowIcon('patientname')">
          PATIENT
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          (click)="applySortPatient('nephrologist')"
          [ngClass]="renderArrowIcon('nephrologist')">
          NEPHROLOGIST
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          (click)="applySort('BirthDate')"
          [ngClass]="renderArrowIcon('BirthDate')">
          DOB (AGE)
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          (click)="applySort('Stage')"
          [ngClass]="renderArrowIcon('Stage')">
          STAGE
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          (click)="applySort('RiskCategory')"
          [ngClass]="renderArrowIcon('RiskCategory')">
          RISK CATEGORY
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          (click)="applySort('PrimaryCareMember')"
          [ngClass]="renderArrowIcon('PrimaryCareMember')">
          SOMATUS LEAD
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          [ngClass]="renderArrowIcon('Status')"
          (click)="applySort('Status')">
          STATUS
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          [ngClass]="renderArrowIcon('AssessmentDate')"
          (click)="applySort('AssessmentDate')">
          LAST ASSESSMENT
        </th>
        <th class="bg-transparent border-bottom-0 fs-12 table-header pe-5"
          [ngClass]="renderArrowIcon('DischargeDate')"
          (click)="applySort('DischargeDate')">
          LAST DISCHARGE
        </th>
        <th class="bg-transparent border-bottom-0 no-btn fs-12 wp-3"></th>
      </tr>
    </thead>
    <tbody *ngIf="showLoading" class="table-body">
      <tr *ngFor="let i of getIterations()">
        <td><span class="skeleton-loader my-1"></span></td>
        <td><span class="skeleton-loader my-1"></span></td>
        <td><span class="skeleton-loader my-1"></span></td>
        <td><span class="skeleton-loader my-1"></span></td>
        <td><span class="skeleton-loader my-1"></span></td>
        <td><span class="skeleton-loader my-1"></span></td>
        <td><span class="skeleton-loader my-1"></span></td>
        <td><span class="skeleton-loader my-1"></span></td>
        <td><span class="skeleton-loader my-1"></span></td>
      </tr>
    </tbody>
    <tbody *ngIf="!showLoading" class="table-body">
      <tr *ngFor="let item of list">
        <td>
          <div class="d-flex align-items-center">
            <!-- <span
              class="data-image avatar avatar-sm rounded-circle"
              style="background-image: url(../assets/images/users/11.jpg)"
            ></span> -->
            <div class="user-details ms-2">
              <a
                class="text-secondary"
                href="javascript: void(0)"
                (click)="patientHandler(item, 'detail')"
                ><h6 class="mb-0">{{ item.patient?.enrollmentNo }}</h6></a
              >
              <!--span class="badge badge-xs bg-success">Approved</span-->
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex align-items-center">
            <!-- <span
              class="data-image avatar avatar-sm rounded-circle"
              style="background-image: url(../assets/images/users/11.jpg)"
            ></span> -->
            <i data-bs-toggle="tooltip" data-bs-placement="top" [title]="item.isRegistered ? 'Registered on MySomatus' : 'Not Registered on MySomatus'" class="fa" [ngClass]="!item.isRegistered ? 'fa-times-circle text-danger' : 'fa-check-circle text-success'"></i>
            <div class="user-details ms-2">
              <a
                class="text-secondary"
                href="javascript: void(0)"
                (click)="patientHandler(item, 'detail')"
                ><h6 class="mb-0">{{ item.patient?.name }}</h6></a
              >
              <!--span class="badge badge-xs bg-success">Approved</span-->
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex align-items-center">
            <div class="user-details ms-2">
                {{ item.nephrologistName ? item.nephrologistName : '-' }}
            </div>
          </div>
        </td>
        <td class="text-center">
          {{ getFormatDate(item.dob)
          }}<strong class="fw-semibold"> ({{ getAge(item.dob) }})</strong>
        </td>
        <td class="text-center">{{ item.stage }}</td>
        <td>
          <div class="d-flex">
            <div class="flex-fill">
              <div class="progress progress-sm mt-1">
                <div
                  *ngIf="item.riskCategory === 'Medium'"
                  class="progress-bar bg-warning wp-50"
                ></div>
                <div
                  *ngIf="item.riskCategory === 'Low'"
                  class="progress-bar bg-success wp-25"
                ></div>
                <div
                  *ngIf="
                    item.riskCategory === 'High' ||
                    item.riskCategory === 'Highest'
                  "
                  class="progress-bar bg-danger wp-75"
                ></div>
              </div>
            </div>
            <div class="ms-2">{{ item.riskCategory }}</div>
          </div>
        </td>
        <td class="text-center">{{ item.careMember }}</td>
        <td class="text-center">{{ item.status }}</td>
        <td class="text-center">
          {{ getFormatDate(item.lastAssignment) }}
          <strong class="fw-semibold"></strong>
        </td>
        <td class="text-center">
          {{ getFormatDate(item.lastDischarge)
          }}<strong class="fw-semibold"></strong>
        </td>
        <td class="text-center">
          <a
            href="#"
            class="br-5 px-2 py-1"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ><i class="fe fe-more-vertical"></i
          ></a>
          <div class="dropdown-menu dropdown-menu-start">
            <!-- <a class="dropdown-item" href="#/patient-profile"
              ><i class="fe fe-edit-2 me-2"></i> Edit</a
            > -->
            <a
              class="dropdown-item"
              href="Javascript: void(0);"
              (click)="patientHandler(item, 'detail')"
              ><i class="fe fe-info me-2"></i> Detail</a
            >
            <a
              *ngIf="item.carePlanStatus === '1'"
              class="dropdown-item"
              href="Javascript: void(0);"
              (click)="patientHandler(item, 'viewCarePlan')"
              ><i class="fe fe-info me-2"></i> View Care Plan</a
            >
          </div>
        </td>
      </tr>
      <!-- <tr *ngIf="list.length == 0 && !showLoading">
        <td>{{ message.noRecords }}</td>
      </tr> -->
    </tbody>
  </table>
  <ng-container *ngIf="!showLoading && list.length > 0">
    <div class="me-5 mt-4 mb-2 d-inline-block">
      <i class="fa fa-check-circle text-success fs-14"></i> Registered on MySomatus
    </div>
    <div class="me-5 mt-4 mb-2 d-inline-block">
      <i class="fa fa-times-circle text-danger fs-14"></i> Not Registered on MySomatus
    </div>
  </ng-container>
  <app-no-records-found
    *ngIf="list.length == 0 && !showLoading"
  ></app-no-records-found>
  <app-pagination
    *ngIf="list.length > 0 && !showLoading"
    [attributePatients]="attributePatients"
    [pagingModel]="gridData.pagingModel"
    (gotoPageHandler)="gotoPage($event)"
  >
  </app-pagination>
</div>
