<div class="side-app">
  <div class="sub-header">
    <div class="main-container container">
      <div class="row">
        <div class="col-6 col-sm-9 col-md-10">
          <h1 class="page-title pt-1">Summary</h1>
        </div>
        <div class="col-6 col-sm-3 col-md-2">
          <!-- <div class="input-group">
            <mbsc-datepicker 
              class="form-control fs-12"
              #rangeDatepicker
              [options]="dateRangeOptions" 
              placeholder="Select date range" ></mbsc-datepicker>
            <button class="input-group-text bg-white"
              type="button"
              (click)="rangeDatepicker.open()">
              <i class="fa fa-calendar"></i>
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="{{ configJson.container }}">
    <div *ngFor="let mainRrow of configJson.rows" class="row {{ mainRrow.row?.className }}">
      <div *ngFor="let col of mainRrow.row.col" class="col-12 col-{{ col.size }}">
        <div *ngFor="let nestedRow of col.rows" class="row">
          <div *ngFor="let nestedCol of nestedRow.row.col"
            class="col-12 col-{{ nestedCol.size }} {{ nestedCol.className }}">

            <div *ngIf="nestedCol.components.length">
              <div *ngIf="nestedCol.components.includes('PatientCountWidget')">
                <div class="card p-4">
                  <div class="d-flex">
                    <div class="notifyimg bg-info">
                      <i class="fa fa-user"></i>
                    </div>
                    <div class="flex-fill d-flex align-items-stretch text-nowrap">
                      <div class="d-sm-flex w-100">
                        <div class="flex-fill border-sm-end px-4 card-style3 py-1">
                          <app-data-card [cardData]="patients"></app-data-card>
                        </div>
                        <div class="flex-fill border-sm-end px-4 card-style3 py-1">
                          <app-data-card [cardData]="engagedPatients"></app-data-card>
                        </div>
                        <div class="flex-fill  px-4 card-style3 py-1">
                          <app-data-card [cardData]="assessedPatients"></app-data-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="nestedCol.components.includes('AdmissionsCountWidget')">
                <div class="card p-4">
                  <div class="d-flex">
                    <div class="notifyimg bg-info">
                      <i class="fa fa-bed"></i>
                    </div>
                    <div class="flex-fill d-flex align-items-stretch text-nowrap">
                      <div class="d-sm-flex w-100">
                        <div class="flex-fill border-sm-end px-4 card-style3 py-1">
                          <app-data-card [cardData]="admissions"></app-data-card>
                        </div>
                        <div class="flex-fill px-4 mt-3 mt-sm-0 py-1">
                          <app-data-card [cardData]="admissionRecent"></app-data-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="nestedCol.components.includes('QualityMatricsWidget')">
                <app-description-card [cardData]="qualityMatrics"></app-description-card>
              </div>
              <div *ngIf="nestedCol.components.includes('RiskStratificationWidget')">
                <app-description-card [cardData]="providerMetrics"></app-description-card>
              </div>
              <div *ngIf="nestedCol.components.includes('PatientByStage')">
                <app-patient-by-stage></app-patient-by-stage>
              </div>

              <div *ngIf="nestedCol.components.includes('PatientByRiskCategory')">
                <app-progress-bar-chart-widget [config]="patientByRiskCategor"></app-progress-bar-chart-widget>
              </div>

              <div *ngIf="nestedCol.components.includes('PatientByComorbidity')">
                <app-progress-bar-chart-widget [config]="patientByComorbidity"></app-progress-bar-chart-widget>
              </div>

              <div *ngIf="nestedCol.components.includes('HoldPatients')">
                <app-hold-patients></app-hold-patients>
              </div>

              <div *ngIf="nestedCol.components.includes('PatientByAgeGroup')">
                <app-progress-bar-chart-widget [config]="patientByAgeGroup"></app-progress-bar-chart-widget>
              </div>
              <div *ngIf="nestedCol.components.includes('CareTeam')">
                <app-care-team></app-care-team>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>