/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PhysicianReportTotals { 
    totalMbrs?: number;
    totalMbrsPercentage?: number;
    totalEngaged?: number;
    totalEngagedPercentage?: number;
    totalNotEngaged?: number;
    totalNotEngagedPercentage?: number;
    totalAssessed?: number;
    totalAssessedPercentage?: number;
    totalNotAssessed?: number;
    totalNotAssessedPercentage?: number;
    totalCKDAdmits?: number;
    totalCKDGroupAdmPercentage?: number;
    totalESKDAdmits?: number;
    totalESKDGroupAdmPercentage?: number;
    totalReAdmits?: number;
    totalReAdmitsPercentage?: number;
    totalNotACEARBSAdherence?: number;
    totalNotACEARBSAdherencePercentage?: number;
    totalNotDiureticAdherence?: number;
    totalNotDiureticAdherencePercentage?: number;
    totalNotBPControl?: number;
    totalNotBPControlPercentage?: number;
    totalNotHBA1CControl?: number;
    totalNotHBA1CControlPercentage?: number;
    totalNotACPAdherence?: number;
    totalNotACPAdherencePercentage?: number;
    totalNotPeritonealDialysis?: number;
    totalNotPeritonealDialysisPercentage?: number;
    totalUnplannedDialysisStarts?: number;
    totalUnplannedDialysisStartsPercentage?: number;
    totalCVC90?: number;
    totalCVC90Percentage?: number;
}