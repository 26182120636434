import { Injectable } from '@angular/core';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInsightService {
  private appInsights: ApplicationInsights;

  constructor() {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.connectionString,
        enableAutoRouteTracking: false,
        disableExceptionTracking: true,
        enableDebug: false,
        extensions: [angularPlugin],
      }
    });
    this.appInsights.loadAppInsights();
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  trackEvent(eventName: string, properties?: { [key: string]: string }) {
    if(properties?.['message'] && properties?.['message'].length > 50){
      eventName += ` (${properties?.['message'].substring(0,47)}...)`;
    } else {
      eventName += ' (' + properties?.['message'] + ')';
    }
    this.appInsights?.trackEvent({ name: eventName }, properties);
  }
  
  trackMetric(metricName: string, value: number, properties?: { [key: string]: string }) {
    this.appInsights?.trackMetric({ name: metricName, average: value }, properties);
  }
  
  trackTrace(message: string, properties?: { [key: string]: string }) {
    this.appInsights?.trackTrace({ message }, properties);
  }
  
  trackErrorEvent(eventName: string, param: any, statueCode: any, statusMessage: any, error: Error, properties?: { [key: string]: string }) {
    const errorProperties = {
      message: statusMessage,
      stack: error.stack,
      statueCode: statueCode,
      param: param,
      properties,
    };
    this.appInsights?.trackEvent({ name: eventName, properties: errorProperties });
  }

  trackPageView(name?: string, url?: string, properties?: { [key: string]: any }) {
    this.appInsights.trackPageView({ name, uri: url, properties });
  }
  
}