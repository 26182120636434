<app-spinner *ngIf="showLoading"></app-spinner>
<app-header></app-header>
<div class="app-content main-content mt-0">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<app-toaster></app-toaster>
<!-- View Details MODAL -->
<div class="modal fade" id="modalSessionTimeout">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo rounded-4 border border-primary border-4">
          <div class="modal-body p-5">
              <div class="text-center">
                  <h3 class="fw-500">Confirmation</h3>
                  <div class="fs-16">There has been no activity. Do you wish to stay logged in?</div>
                  <div class="d-flex alert alert-info my-4 rounded-3 bg-info">
                      <span class="alert-inner--icon me-2"><i class="fe fe-info fs-18 text-danger"></i></span>
                      <div class="flex-fill text-start alert-inner--text lh-normal text-dark d-flex-align-items-center">By clicking No button you will be logout.</div>
                  </div>
              </div>
              <div class="text-center mt-4">
                  <button class="btn btn-secondary mx-1 py-2 px-5 fw-bold" data-bs-dismiss="modal"
                  (click)="stayloggedIn()">
                      Yes
                  </button>
                  <button class="btn btn-outline-light mx-1 py-2 px-5 fw-bold" data-bs-dismiss="modal" type="button" (click)="loggedOutHandler()">
                    No
                   </button>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="modalUserLoggedout">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-demo rounded-4 border border-primary border-4">
        <div class="modal-body p-5">
            <div class="text-center">
                <h3 class="fw-500">Oops! You have been signed out</h3>
                <div class="fs-16">Your login session has timed out due to inactivity,<br/>please Sign in again</div>
            </div>
        </div>
        <div class="modal-footer text-center align-items-center justify-content-center">
          <a href="javascript:void(0);" class="a-primary text-primary m-0 fs-16" data-bs-dismiss="modal">
            <i class="fa fa-long-arrow-right me-2"></i>
              Return to Sign in
          </a>
      </div>
    </div>
</div>
</div>
