<div class="side-app">
  <div class="main-container container">
    <div class="p-5 text-center">
      <div class="ms-auto">
        <div class="mb-4">
          <img
            class="wrap-figure__img"
            src="../../../assets/images/error.png"
            alt="Error 404"
          />
        </div>
        <h2 class="fw-bold" *ngIf="!errorMessage.title">
          {{ errorMessage.title }}
          <i class="fa fa-exclamation-triangle text-danger"></i>
        </h2>

        <div class="text-gray mb-6" *ngIf="!errorMessage.body">
          {{ errorMessage.body }}
        </div>

        <div *ngIf="errorMessage.type !== '404'">
          <a routerLink="/" class="btn btn-outline-primary mx-1 px-5"
            ><i class="fa fa-undo me-1"></i> Home Page</a
          >
          <a href="javascript: void(0);" (click)="logOut()" class="btn btn-dark mx-1 px-6"
            ><i class="fa fa-sign-out me-1"></i> Log out</a
          >
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
