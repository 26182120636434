<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-5 text-center text-sm-start">
         {{ messages.COPYRIGHT_MESSAGE }}
      </div>
      <div class="col-12 col-sm-7 text-center text-sm-end op-0-5">
        <app-version></app-version>
      </div>
    </div>
  </div>
  <!-- <script async src="https://www.googletagmanager.com/gtag/js?id={{gtagId}}"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '{{gtagId}}');
  </script> -->
</footer>
