/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MonthlyBilling } from './monthlyBilling';

export interface ContractUpsertModel { 
    internalId?: number;
    programType: string;
    contractType?: string;
    pmPmAmount?: number;
    pmAmount?: number;
    mraFlag?: boolean;
    mraRate?: number;
    pmpmAssessedOrEngaged?: string;
    assessedAsOfDate?: Date;
    pmPmStartDate?: Date;
    contractStartDate?: Date;
    contractEndDate?: Date;
    actualGoLiveDate?: Date;
    actualTermDate?: Date;
    contractGroupType?: string;
    invoiceForTime?: boolean;
    hourlyRate?: number;
    hourlyCap?: number;
    medicalDirectorship?: boolean;
    enrollmentBonusEligible?: boolean;
    qualityIncentiveEligible?: boolean;
    ckdadkBaseline?: string;
    ckdadkBaselineValue?: number;
    eskdadkBaseline?: string;
    eskdadkBaselineValue?: number;
    implementationFee?: boolean;
    implementationFeeValue?: number;
    networkId?: number;
    isActive?: boolean;
    prePartnershipPerformancePeriodStartDate?: Date;
    prePartnershipPerformancePeriodEndDate?: Date;
    monthlyBilling?: Array<MonthlyBilling>;
    id?: number;
}