import { Component } from '@angular/core';
import { CareTeamModel, PracticeService } from 'src/app/api-client';
import { Messages } from 'src/app/shared/common-constants/messages';
import { ICustomLogEvents, ISessionDetails } from 'src/app/interfaces/customLogEvents';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-care-team',
  templateUrl: './care-team.component.html',
  styleUrls: ['./care-team.component.scss']
})
export class CareTeamComponent {
  selectedPractice: any;
  userInfo: any;
  showLoading: boolean = false;
  errorMessage: string | null = null;
  careTeamList: CareTeamModel[] = [];
  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = 'Dashboard - Web';

  constructor(private _practiceService: PracticeService, 
    private loggingService: LoggingService) {

  }
  ngOnInit() {
    this.loggingService.eventService.selectedPracticeSubscription().subscribe((practice) => {
      this.selectedPractice = practice;
    });
    this.loggingService.storeService.userInfoSubscription().subscribe((info) => {
      this.userInfo = info;
    });
    this.sessionDetails = this.loggingService.getSessionDetails(this.userInfo, this.selectedPractice);  
    this.getCareTeamList();
  }
  private getCareTeamList() {
    this.showLoading = true;
    this.customLog = this.loggingService.createCustomLog('API to get Care Team Members', '', 'INFO', this.sessionDetails, '/api/Practice/careTeamMembers/list', 'GET', {});
    this._practiceService.apiPracticeCareTeamMembersListGet().subscribe((careTeamMemberList: CareTeamModel[]) => {
      if (!careTeamMemberList) {
        this.errorMessage = Messages.noData;
        this.customLog.message = 'API Response - No Data Found';
      } else {
        this.careTeamList = careTeamMemberList.filter((member: any) => {
          return !member.name.toLowerCase().includes('engage') && !member.designation.toLowerCase().includes('admin');
        });
        this.customLog.message = 'API Call Successful';
      }
      this.loggingService.trackEvent(this.customEvent, this.customLog);
      this.showLoading = false;
    },
    (error) => {
      this.errorMessage = Messages.errorFetchingData;
      this.customLog.level = 'ERROR';
      this.customLog.message = error;
      this.loggingService.trackEvent(this.customEvent, this.customLog);
      this.loggingService.logException(error);
    })
  }
}
