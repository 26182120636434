<div class="aside-section__outer row mx-0">
  <div
    class="d-none d-md-flex wrap-figure100 col-md-6 col-lg-7 col-xl-8 px-0 bg-gray-300"
  >
    <div>
      <span class="light-theme-img">
        <img
          class="wrap-figure100__img"
          src="../../../assets/images/login.jpg"
          alt="More Lives, Better Lived."
        />
      </span>
      <span class="dark-theme-img">
        <img
          class="wrap-figure100__img"
          src="../../../assets/images/login-dark.jpg"
          alt="More Lives, Better Lived."
        />
      </span>
      <div class="px-4 py-2">{{ messages.COPYRIGHT_MESSAGE }}</div>
    </div>
  </div>
  <div class="col-md-6 col-lg-5 col-xl-4 px-xl-7 px-6 bg-white">
    <div>
      <div class="pt-7 pb-3">
        <span class="light-theme-img">
          <img
            class="img-fluid"
            src="../../../assets/images/brand/renalliqconnect.png"
            alt="renallIQconnect"
          />
        </span>
        <span class="dark-theme-img">
          <img
            class="img-fluid"
            src="../../../assets/images/brand/renalliqconnect-dark.png"
            alt="renallIQconnect"
          />
        </span>
      </div>
      <hr class="mb-0" />
    </div>
    <form class="aside-section validate-form">
      <div class="flex-fill pt-4 pb-6">
        <div class="pb-3">
          <h2 class="fw-bold">
            {{ messages.WELCOME_TEXT }}<br />{{ messages.PRODUCTNAME }}
          </h2>
        </div>
        <div class="form-row">
          <div class="col-6">
            <label class="form-label mt-3" for="firstName">First Name</label>
            <div class="wrap-input100 validate-input">
              <input
                class="input100 ps-4"
                type="text"
                name="firstName"
                placeholder="First Name"
                id="firstName"
              />
            </div>
          </div>
          <div class="col-6">
            <label class="form-label mt-3" for="lastName">Last Name</label>
            <div class="wrap-input100 validate-input">
              <input
                class="input100 ps-4"
                type="text"
                name="lastName"
                placeholder="Last Name"
                id="lastName"
              />
            </div>
          </div>
        </div>

        <label class="form-label" for="emailId">Email Id</label>
        <div class="wrap-input100 validate-input">
          <input
            class="input100 ps-4"
            type="text"
            name="emailId"
            placeholder="Email Id"
            id="emailId"
          />
        </div>
        <label class="form-label mt-3" for="mobileNumber">Mobile Number</label>
        <div class="wrap-input100 validate-input">
          <input
            class="input100 ps-4"
            type="text"
            name="mobileNumber"
            placeholder="Mobile Number"
            id="mobileNumber"
          />
        </div>
        <label class="form-label mt-3" for="providerName">Provider Name</label>
        <div class="wrap-input100 validate-input">
          <input
            class="input100 ps-4"
            type="text"
            name="providerName"
            placeholder="Provider Name"
            id="providerName"
          />
        </div>

        <div class="row">
          <div class="col-6 py-2">
            <a
              [routerLink]="['/signin']"
              class="d-inline-block fw-semibold mt-2"
              ><i class="fa fa-mail-reply me-1"></i> Sign In</a
            >
          </div>
          <div class="col-6 text-end py-2">
            <button type="submit" class="btn btn-dark py-2 px-6">
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </form>
    <div>
      <hr class="mt-0" />
      <div class="text-center fs-12 op-0-5 text-sm-end pb-3">
        <app-version></app-version>
      </div>
    </div>
  </div>
</div>
