import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { GoogleMapService } from './services/google-map.service';
import { EventService } from './services/event.service';
import { AuthService } from './services/auth.service';
import { LocalStorageService } from './shared/services/localstorage.service';
import { CommonConstants } from './shared/common-constants/common-constants';
import { HomeService } from './api-client';
declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'new-angular-app';
  unlistener: any;
  unlistenerScroll: any;
  logoutWaitingTimer: any;
  showLoading: boolean = false;
  currentRoute!: string;
  showHeader: boolean = true
  userLoggedOut: boolean = false;
  constructor(private router: Router, private gaService: GoogleAnalyticsService,
    private _localStorage: LocalStorageService,
    private gMapService: GoogleMapService, private eventService: EventService,
    private authService: AuthService,
    private renderer2: Renderer2,
    private homeservice: HomeService) {
    this.router.events.subscribe(event => {
      try {
        if (event instanceof NavigationEnd) {
          const routeNameArr = event.urlAfterRedirects.split('/');
          this.currentRoute = routeNameArr[1];
          (<any>window).dataLayer.push({
            event: 'virtualPageview',
            virtualPageURL: document.location.origin + event.urlAfterRedirects,
            virtualPageTitle: routeNameArr[routeNameArr.length -1],
          });
        }
      } catch(error) {
        console.log('error:', error);
      }
    });
  }
  ngOnInit(): void {
    this.userLoggedOut = false;
    this.unlistener = this.renderer2.listen("document", "mousemove", event => {
      this.checkLoginStatus();
    });
    this.unlistenerScroll = this.renderer2.listen("document", "scroll", event => {
      this.checkLoginStatus();
    });
    this.eventService.sessionCheckTimerSubscription().subscribe((value: any) => {
      if (value === 'timeout') {
        if (!this.authService.isLoggedIn()) return;
        this.logoutWaitingTimer = setTimeout(() => {
          this.eventService.stopTimer();
          clearTimeout(this.logoutWaitingTimer);
          $('#modalSessionTimeout').modal('hide');
          this.authService.logOut();
          $('#modalUserLoggedout').modal('show');
        }, 300000);
        $('#modalSessionTimeout').modal('show');
      }
    })
    this.gaService.init();
    this.gMapService.init();
    // ----- Horizontal Style ------- //
    $('body').addClass('horizontal');
    let bodyhorizontal = $('body').hasClass('horizontal');
    if (bodyhorizontal) {
      $('body').addClass('horizontal');
      $('.main-content').addClass('hor-content');
      $('.main-content').removeClass('app-content');
      $('.main-container').addClass('container');
      $('.main-container').removeClass('container-fluid');
      $('.app-header').addClass('hor-header');
      $('.hor-header').removeClass('app-header');
      $('.app-sidebar').addClass('horizontal-main');
      $('.main-sidemenu').addClass('container');
      $('body').removeClass('sidebar-mini');
      $('body').removeClass('sidenav-toggled');
      $('body').removeClass('horizontal-hover');
      $('body').removeClass('default-menu');
      $('body').removeClass('icontext-menu');
      $('body').removeClass('icon-overlay');
      $('body').removeClass('closed-leftmenu');
      $('body').removeClass('hover-submenu');
      $('body').removeClass('hover-submenu1');
      localStorage.setItem('horizantal', 'True');
      // To enable no-wrap horizontal style
      $('#slide-left').removeClass('d-none');
      $('#slide-right').removeClass('d-none');
      // To enable wrap horizontal style
      // $('#slide-left').addClass('d-none');
      // $('#slide-right').addClass('d-none');
      // document.querySelector('.horizontal .side-menu').style.flexWrap = 'wrap'
      if (window.innerWidth >= 992) {
        let li = document.querySelectorAll('.side-menu li');
        li.forEach((e, i) => {
          e.classList.remove('is-expanded');
        });
        var animationSpeed = 300;
        // first level
        var parent = $("[data-bs-toggle='sub-slide']").parents('ul');
        var ul = parent.find('ul:visible').slideUp(animationSpeed);
        ul.removeClass('open');
        var parent1 = $("[data-bs-toggle='sub-slide2']").parents('ul');
        var ul1 = parent1.find('ul:visible').slideUp(animationSpeed);
        ul1.removeClass('open');
      }
    }
    // ----- Horizontal Style ------- //

    //______Select2
    $('.select2').select2({
      minimumResultsForSearch: Infinity,
    });
  }
  checkLoginStatus() {
    if (!this.authService.isLoggedIn()) {
      if (this.currentRoute !== '' &&
        this.currentRoute !== 'login' &&
        this.currentRoute !== 'twofactorauth' &&
        this.currentRoute !== 'resetPassword' &&
        this.currentRoute !== 'forgotPassword' &&
        this.currentRoute !== 'signup'
      ) {
        this.authService.logOut();
        if (!this.userLoggedOut) {
          $('#modalUserLoggedout').modal('show');
          this.userLoggedOut = true;
        }
        return;
      }
    } else {
      const expirationTime = this._localStorage.getItem(CommonConstants.EXPIRATION_TIME);
      if (expirationTime) {
        let minutesLeft = new Date(expirationTime).getTime() - new Date().getTime();
        if (minutesLeft <= 600000) {
          const expires_in = this.addMinutes(new Date(), 30);
          this._localStorage.setItem(CommonConstants.EXPIRATION_TIME, expires_in.toString());
          this.resfreshAPI();
        }
      }
    }
    this.eventService.startTimer();
  }
  loggedOutHandler(): void {
    this.eventService.stopTimer();
    clearTimeout(this.logoutWaitingTimer);
    this.authService.logOut();
  }
  stayloggedIn(): void{
    this.showLoading = true;
    this.eventService.startTimer();
    clearTimeout(this.logoutWaitingTimer);
    const expires_in = this.addMinutes(new Date(), 30);
    this._localStorage.setItem(CommonConstants.EXPIRATION_TIME, expires_in.toString());
    this.resfreshAPI();
  }
  addMinutes(date: Date, minutes: number) {
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  }
  ngOnDestroy() {
    this.unlistener();
    this.unlistenerScroll();
  }

  resfreshAPI(){
    this.showLoading = true;
    this.homeservice.rootGet().subscribe(
      {
        next: (status: any) => {
          this.showLoading = false;
        },
        error: (error) => {
          this.showLoading = false;
        }
      }
    );
  }
}
