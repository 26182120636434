<div class="card">
    <div class="card-header border-bottom title-submenu">
      <h3 class="card-title flex-fill">Current On Hold Status Breakdown (for members on hold)</h3>
    </div>
    <div class="card-body pt-9 pb-8">
      <div class="donut-chart__wrapper" style="height: 258px;">
        <app-doughnut-chart 
          [config]="chartConfig">
        </app-doughnut-chart>
      </div>
    </div>
  </div>