import { Component, OnInit } from '@angular/core';
import { PaymentReportService } from 'src/app/api-client';
import { ICustomLogEvents, ISessionDetails } from 'src/app/interfaces/customLogEvents';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  iterationCount: number = 10;
  selectedPractice: any;
  userInfo: any;
  loadingPymentReport: boolean = false;
  paymentReport: any = {
    summary: {},
    categories: [],
    series: [
      {
        type: 'column',
        name: 'Patient Count',
        data: []
      },
      {
        type: 'column',
        name: 'Engaged Patients',
        data: []
      },
      {
        type: 'line',
        name: 'PMPM Payment Amount',
        data: []
      },
      {
        type: 'line',
        name: 'Incentive at 80%',
        data: []
      },
      {
        type: 'line',
        name: 'Incentive at 100%',
        data: []
      }
    ],
    data: []
  }

  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = 'Account - Web';

  constructor(private paymentReportService: PaymentReportService,
    private loggingService: LoggingService,
  ) { }

  ngOnInit(): void {
    this.loggingService.eventService.selectedPracticeSubscription().subscribe((practice) => {
      this.selectedPractice = practice;
    });
    this.loggingService.storeService.userInfoSubscription().subscribe((info) => {
      this.userInfo = info;
    });
    this.sessionDetails = this.loggingService.getSessionDetails(this.userInfo, this.selectedPractice);
    this.loggingService.logEventForComponentRendering(this.customEvent, this.sessionDetails);
    this.loadPaymentReport();
  }

  loadPaymentReport() {
    this.loadingPymentReport = true;
    this.customLog = this.loggingService.createCustomLog('Payment Report API', '', 'INFO', this.sessionDetails, '/api/PaymentReport', 'GET', {});
    this.paymentReportService.apiPaymentReportGetPaymentReportGet().subscribe({
      next: (response: any) => {
        if (response.data) {
          this.paymentReport.data = response.data;
          this.paymentReport.summary = response.summary;
          response.data.forEach((data: any) => {
            this.paymentReport.series[0].data.push(data.attributedPatients);
            this.paymentReport.series[1].data.push(data.engaged);
            this.paymentReport.series[2].data.push(data.pmpmPaymentAmount);
            this.paymentReport.series[3].data.push(parseFloat(data.incentiveAtEightyPercent.toFixed(2)));
            this.paymentReport.series[4].data.push(parseFloat(data.incentiveAtHundredPercent.toFixed(2)));
            this.paymentReport.categories.push(data.reportingPeriod);
          });
          this.loadingPymentReport = false;
          this.customLog.message = 'API Call Successful';
          this.loggingService.trackEvent(this.customEvent, this.customLog);
        }
      },
      error: (error: any) => {
        this.loadingPymentReport = false;
        this.customLog.level = 'ERROR';
        this.customLog.message = error;
      this.loggingService.trackEvent(this.customEvent, this.customLog);
      this.loggingService.logException(error);
      }
    });
  }
  getReportingPeriodLink(reportingPeriod: string) {
    return reportingPeriod.replace(/\s+/g, '-').toLowerCase();
  }
  getIterations(): number[] {
    return new Array(this.iterationCount);
  }
}
