import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, Configuration } from '../api-client';
import { UserInfo } from '../interfaces/user';
import { CommonConstants } from '../shared/common-constants/common-constants';
import { LocalStorageService } from '../shared/services/localstorage.service';
import { StoreService } from './store.service';
import { UserTokenRequestModel } from '../interfaces/user-token-request-model';
import { LoginResponseModel } from '../interfaces/login-response-model';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ICustomLogEvents, ISessionDetails } from '../interfaces/customLogEvents';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected basePath = environment.baseApiUrl;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  selectedPractice: any;
  userInfo: any;

  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = 'User Authentication - Web';

  constructor(private _localStorage: LocalStorageService,
    private storeService: StoreService,
    private _accountService: AccountService,
    protected httpClient: HttpClient,
    private loggingService: LoggingService,
    private route: Router) {
      this.loggingService.eventService.selectedPracticeSubscription().subscribe((practice) => {
        this.selectedPractice = practice;
      });
      this.loggingService.storeService.userInfoSubscription().subscribe((info) => {
        this.userInfo = info;
      });
      this.sessionDetails = this.loggingService.getSessionDetails(this.userInfo, this.selectedPractice);
  }
  
  isLoggedIn() {
    const userInfo: UserInfo = this.storeService.getUserInfo();
    if (!userInfo || !userInfo.fullName) {
      return false;
    }
    if (this.isUserSessionExpired()) {
      return false;
    }
    return !!this._localStorage.getItem(CommonConstants.CONNECT_TOKEN_KEY);
  }
  isUserSessionExpired() {
    const expirationTime = this._localStorage.getItem(CommonConstants.EXPIRATION_TIME);
    if (expirationTime) {
      if (new Date().getTime() > new Date(expirationTime).getTime()) {
        return true;
      }
    }
    return false;
  }
  public async logOut() {
    this.customLog = this.loggingService.createCustomLog('Logout API', '', 'INFO', this.sessionDetails, '/api/Account/Logout', 'POST', {});
    this.loggingService.trackEvent(this.customEvent, this.customLog);
    const path: string = window.location.hash;
    if (path.indexOf('/publish') !== -1) {
      this.storeService.setCurrentRoute(path.replace('#', ''));
    }
    try {
      await this._accountService.apiAccountLogoutPost().toPromise();
      this.customLog.message = 'User Logout Successful';
      this.loggingService.trackEvent(this.customEvent, this.customLog);
    } catch (ex: any) {
      console.log(ex);
      this.customLog.level = 'ERROR';
      this.customLog.message = ex;
      this.loggingService.trackEvent(this.customEvent, this.customLog);
      this.loggingService.logException(ex);
    } finally {
      this._localStorage.clearAll();
      this.route.navigate(['/login']);
    }
  }
  /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public apiAccountAuthtokenValidatePost(body?: UserTokenRequestModel, observe?: 'body', reportProgress?: boolean): Observable<LoginResponseModel>;
  public apiAccountAuthtokenValidatePost(body?: UserTokenRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginResponseModel>>;
  public apiAccountAuthtokenValidatePost(body?: UserTokenRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginResponseModel>>;
  public apiAccountAuthtokenValidatePost(body?: UserTokenRequestModel, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

    const to_encoded = (obj: any) => Object.keys(obj).map(k =>
      `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');

    return this.httpClient.request<LoginResponseModel>('post', `${this.basePath}/connect/token`,
      {
        body: to_encoded(body),
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
