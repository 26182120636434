<div class="main-container container">
  <div class="card my-4">
    <div class="card-header title-submenu d-flex border-bottom">
      <h3 class="card-title flex-fill">Alerts Samples</h3>
    </div>
    <div class="card-body pt-0">
      <div class="example mt-4">
        <div
          class="alert alert-success alert-dismissible d-flex fade show"
          role="alert"
        >
          <div>
            <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>
          </div>
          <div class="flex-fill">
            Well done! You have successfully read this important alert message.
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div
          class="alert alert-info alert-dismissible d-flex fade show"
          role="alert"
        >
          <div><i class="fa fa-bell-o me-2" aria-hidden="true"></i></div>
          <div class="flex-fill">
            Heads up! This alert needs your attention, but it's not super
            important.
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div
          class="alert alert-warning alert-dismissible d-flex fade show"
          role="alert"
        >
          <div><i class="fa fa-exclamation me-2" aria-hidden="true"></i></div>
          <div class="flex-fill">
            Warning! Better check yourself, you're not looking too good.
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div
          class="alert alert-danger alert-dismissible d-flex fade show"
          role="alert"
        >
          <div><i class="fa fa-frown-o me-2" aria-hidden="true"></i></div>
          <div class="flex-fill">
            Oh snap! Change a few things up and try submitting again.
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card my-4">
    <div class="card-header title-submenu d-flex border-bottom">
      <h3 class="card-title flex-fill">Form Validation</h3>
    </div>
    <div class="card-body pt-0">
      <div class="example mt-4">
        <form class="row g-3 needs-validation" novalidate>
          <div class="col-12">
            <label for="validationCustom01" class="form-label"
              >First name</label
            >
            <div class="wrap-input100">
              <input
                type="text"
                class="form-control input100"
                id="validationCustom01"
                value="Mark"
                required
              />
              <span class="symbol-input100"
                ><i aria-hidden="true" class="zmdi zmdi-email"></i
              ></span>
              <div class="valid-feedback">Looks good!</div>
              <div class="invalid-feedback">
                Please provide a valid First name.
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="password2" class="form-label">Password</label>
            <div
              data-bs-validate="Password is required"
              class="wrap-input100 input-password"
            >
              <input
                id="password2"
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                formcontrolname="confirmPassword"
                class="form-control input100"
                required
              />
              <i class="fe fe-eye input-password__icon"></i>
              <i class="fe fe-eye-off input-password__icon"></i>
              <span class="symbol-input100">
                <i aria-hidden="true" class="zmdi zmdi-lock"></i>
              </span>
              <div class="valid-feedback">Looks good!</div>
              <div class="invalid-feedback">
                Please provide a valid Password
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="validationCustom02" class="form-label">Last name</label>
            <input
              type="text"
              class="form-control input100 ps-4"
              id="validationCustom02"
              value="Otto"
              required
            />
            <div class="valid-feedback">Looks good!</div>
            <div class="invalid-feedback">Please provide a valid Last name</div>
          </div>

          <div class="col-12">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label class="form-check-label" for="invalidCheck">
                Agree to terms and conditions
              </label>
              <div class="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </div>
          <div class="col-12">
            <button class="btn btn-dark py-2 px-5" type="submit">
              Submit form
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
