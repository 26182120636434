import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ICustomLogEvents, ISessionDetails } from 'src/app/interfaces/customLogEvents';
import { ProgressBarChartWidgetInput } from 'src/app/interfaces/progress-bar-chart-widget';
import { LoggingService } from 'src/app/services/logging.service';
import { Messages } from 'src/app/shared/common-constants/messages';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-progress-bar-chart-widget',
  templateUrl: './progress-bar-chart-widget.component.html',
  styleUrls: ['./progress-bar-chart-widget.component.scss']
})
export class ProgressBarChartWidgetComponent implements OnInit {  
  selectedPractice: any;
  userInfo: any;
  showLoading: boolean = false;
  errorMessage: string | null = null;
  chartConfig: ProgressBarChartWidgetInput = {} as ProgressBarChartWidgetInput;
  chartData: any = [];
  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = 'Dashboard - Web';

  @Input() set config(inputValue: ProgressBarChartWidgetInput) {
    if (inputValue) {
      this.chartConfig = inputValue;
    }
  }
  constructor(private httpClient: HttpClient,
    private loggingService: LoggingService
  ) {

  }
  ngOnInit(): void {
    this.loggingService.eventService.selectedPracticeSubscription().subscribe((practice) => {
      this.selectedPractice = practice;
    });
    this.loggingService.storeService.userInfoSubscription().subscribe((info) => {
      this.userInfo = info;
    });
    this.sessionDetails = this.loggingService.getSessionDetails(this.userInfo, this.selectedPractice);
    this.loadChartData(this.chartConfig.apiUrl);
  }
  private loadChartData(url: string): void {
    this.showLoading = true;
    this.customLog = this.loggingService.createCustomLog('API Call', '', 'INFO',this.sessionDetails, `/api/${url}`, 'GET', {});
    this.httpClient.get(`${environment.baseApiUrl}/api/${url}`).subscribe((data: any) => {
      if (data) {
        this.chartData = data;
        this.showLoading = false;
        this.errorMessage = null;
        this.customLog.message = 'API Call Successful';
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        return;
      }
      this.errorMessage = Messages.noData;
    },
    (error) => {
      this.errorMessage = Messages.errorFetchingData;
      this.customLog.level = 'ERROR';
      this.customLog.message = {error} ;
      this.loggingService.trackEvent(this.customEvent, this.customLog);
      this.loggingService.logException(error);
    })
  }
}
