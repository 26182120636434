<div class="aside-section__outer row mx-0">
  <div
    class="d-none d-md-flex wrap-figure100 col-md-6 col-lg-7 col-xl-8 px-0 bg-gray-300"
  >
    <div>
      <span class="light-theme-img">
        <img
          class="wrap-figure100__img"
          src="../../../assets/images/login.jpg"
          alt="More Lives, Better Lived."
        />
      </span>
      <span class="dark-theme-img">
        <img
          class="wrap-figure100__img"
          src="../../../assets/images/login-dark.jpg"
          alt="More Lives, Better Lived."
        />
      </span>
      <div class="px-4 py-2">{{ messages.COPYRIGHT_MESSAGE }}</div>
    </div>
  </div>
  <div class="col-md-6 col-lg-5 col-xl-4 px-xl-7 px-6 bg-white">
    <app-spinner *ngIf="showLoading"></app-spinner>
    <div>
      <div class="pt-7 pb-3">
        <span class="light-theme-img">
          <img
            class="img-fluid"
            src="../../../assets/images/brand/renalliqconnect.png"
            alt="renallIQconnect"
          />
        </span>
        <span class="dark-theme-img">
          <img
            class="img-fluid"
            src="../../../assets/images/brand/renalliqconnect-dark.png"
            alt="renallIQconnect"
          />
        </span>
      </div>
      <hr class="mb-0" />
    </div>
    <form
      class="aside-section validate-form"
      name="twoFAForm"
      [formGroup]="twoFAForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="flex-fill pt-4 pb-6">
        <div>
          <h2 class="fw-bold">
            {{ messages.verificationCodeText }}<br />{{ messages.continue }}
          </h2>
        </div>
        <div class="text-gray mb-6">
          {{ messages.sendTo }} <span class="text-primary">{{ username }}</span
          >. {{ messages.checkSpamMessage }}
        </div>

        <label class="form-label my-3" for="verificationCode">
          <i class="zmdi zmdi-lock me-1" aria-hidden="true"></i> Verification
          Code
        </label>
        <div class="wrap-input100">
          <div class="form-row">
            <div class="col-12">
              <input
                class="input100 ps-4 text-center verification-code-input no-caret"
                type="text"
                maxlength="1"
                name="pass"
                formControlName="digit1"
                (paste)="onPaste($event, 0)"
                id="verificationCode1"
                (keypress)="acceptAlphaNumeric($event)"
                (keydown)="backspacekeyEvent($event)"
                [attr.autocomplete]="'off'"
                required
              />
              <input
                class="input100 ps-4 text-center verification-code-input ml2perc no-caret"
                type="text"
                name="pass"
                maxlength="1"
                formControlName="digit2"
                (paste)="onPaste($event, 1)"
                id="verificationCode2"
                (keypress)="acceptAlphaNumeric($event)"
                (keydown)="backspacekeyEvent($event)"
                [attr.autocomplete]="'off'"
                required
              />
              <input
                class="input100 ps-4 text-center verification-code-input ml2perc no-caret"
                type="text"
                name="pass"
                maxlength="1"
                formControlName="digit3"
                (paste)="onPaste($event, 2)"
                id="verificationCode3"
                (keypress)="acceptAlphaNumeric($event)"
                (keydown)="backspacekeyEvent($event)"
                [attr.autocomplete]="'off'"
                required
              />
              <input
                class="input100 ps-4 text-center verification-code-input ml2perc no-caret"
                type="text"
                name="pass"
                maxlength="1"
                formControlName="digit4"
                (paste)="onPaste($event, 3)"
                id="verificationCode4"
                (keypress)="acceptAlphaNumeric($event)"
                (keydown)="backspacekeyEvent($event)"
                [attr.autocomplete]="'off'"
                required
              />
              <input
                class="input100 ps-4 text-center verification-code-input ml2perc no-caret"
                type="text"
                name="pass"
                maxlength="1"
                formControlName="digit5"
                (paste)="onPaste($event, 4)"
                id="verificationCode5"
                (keypress)="acceptAlphaNumeric($event)"
                (keydown)="backspacekeyEvent($event)"
                [attr.autocomplete]="'off'"
                required
              />
              <input
                class="input100 ps-4 text-center verification-code-input ml2perc no-caret"
                type="text"
                name="pass"
                maxlength="1"
                formControlName="digit6"
                (paste)="onPaste($event, 5)"
                id="verificationCode6"
                (keypress)="acceptAlphaNumeric($event)"
                (keydown)="backspacekeyEvent($event)"
                [attr.autocomplete]="'off'"
                required
              />
            </div>
          </div>

          <!-- <span
      class="text-danger"
      *ngIf="(pass.touched || twoFAForm.submitted) && pass.invalid"
    >
      Verification code is required
    </span> -->
          <span class="text-danger" *ngIf="requiredFieldError">
            {{ messages.verificationCodeRequired }}
          </span>
        </div>

        <div class="row">
          <div class="col-6 py-2">
            <a
              href="javascript: void(0);"
              (click)="resendTwoFAToken($event)"
              [style.cursor]="isDisabled ? 'not-allowed' : 'pointer'"
              [style.pointer-events]="isDisabled ? 'none' : 'auto'"
              class="d-inline-block fw-semibold mt-2"
              *ngIf="showResendCode"
              >{{ messages.verificationCodeResend }}</a
            >
          </div>
          <div class="col-6 text-end py-2">
            <!--[disabled]="!twoFAForm.valid"-->
            <button type="submit" class="btn btn-dark py-2 px-6">
              Continue
            </button>
          </div>
        </div>
        <div>
          <div
            class="alert alert-danger d-flex fade show"
            *ngIf="errorMessage"
            role="alert"
          >
            <div><i class="fa fa-warning me-2" aria-hidden="true"></i></div>
            <div class="flex-fill">
              {{ errorMessage }}
            </div>
          </div>
        </div>

        <!---->
        <div>
          <div
            class="alert alert-success d-flex fade show"
            *ngIf="successMsg"
            role="alert"
          >
            <div><i class="fa fa-check-circle me-2" aria-hidden="true"></i></div>
            <div class="flex-fill">
              {{ successMsg }}
            </div>
          </div>
        </div>
      </div>
    </form>

    <div>
      <hr class="mt-0" />
      <div class="text-center fs-12 op-0-5 text-sm-end pb-3">
        <app-version></app-version>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="termsAndConditions" *ngIf="!agreed">
  <div class="modal-dialog modal-dialog-centered" role="dialog" aria-modal="true">
    <div class="modal-content modal-content-demo rounded-4 border border-primary border-4">
      <div class="modal-body p-5">
        <div class="text-center">
          <h3 class="fw-500">Updated Terms of Use</h3>
          <div class="fs-16">We have updated our <a href="#/terms-and-conditions" target="_blank">Terms and Conditions</a> for using the RenalIQ Connect software. By
            clicking "I Agree" below, you confirm that you have read and agree to our updated terms.</div>
        </div>
        <div class="text-center mt-4">
          <button id="agreeButton" class="btn btn-secondary mx-1 py-2 px-5 fw-bold" data-bs-dismiss="modal">I
            Agree</button>
          <button id="cancelButton" class="btn btn-outline-light mx-1 py-2 px-5 fw-bold" data-bs-dismiss="modal"
            type="button">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>