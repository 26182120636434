<app-spinner *ngIf="showLoading"></app-spinner>
<div class="card chart-wrapper">
  <div class="card-header title-submenu d-flex">
    <h3 class="card-title flex-fill">{{ chartConfig.title }}</h3>
    
  </div>
  
  <div class="card-body pt-1 mh-11">
<highcharts-chart
    *ngIf="!errorMessage"
    style="width: 100%; height: 100%; display: block;"
    [Highcharts]="Highcharts"
    [options]="option">
</highcharts-chart>
<h5><i>{{ chartConfig.footer }}</i></h5>
<div *ngIf="errorMessage">
    <div class="alert alert-danger d-flex fade show"
      role="alert">
      <div><i class="fa fa-warning me-2" aria-hidden="true"></i></div>
      <div class="flex-fill">
        {{ errorMessage }}
      </div>
    </div>
</div>
</div>