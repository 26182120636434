<div class="aside-section__outer row mx-0">
  <div
    class="d-none d-md-flex wrap-figure100 col-md-6 col-lg-7 col-xl-8 px-0 bg-gray-300"
  >
    <div>
      <span class="light-theme-img">
        <img
          class="wrap-figure100__img"
          src="../../../assets/images/login.jpg"
          alt="More Lives, Better Lived."
        />
      </span>
      <span class="dark-theme-img">
        <img
          class="wrap-figure100__img"
          src="../../../assets/images/login-dark.jpg"
          alt="More Lives, Better Lived."
        />
      </span>
      <div class="px-4 py-2">{{ messages.COPYRIGHT_MESSAGE }}</div>
    </div>
  </div>
  <div class="col-md-6 col-lg-5 col-xl-4 px-xl-7 px-6 bg-white">
    <div>
      <div class="pt-7 pb-3">
        <span class="light-theme-img">
          <img
            class="img-fluid"
            src="../../../assets/images/brand/renalliqconnect.png"
            alt="renallIQconnect"
          />
        </span>
        <span class="dark-theme-img">
          <img
            class="img-fluid"
            src="../../../assets/images/brand/renalliqconnect-dark.png"
            alt="renallIQconnect"
          />
        </span>
      </div>
      <hr class="mb-0" />
    </div>
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit(form)"
      novalidate
      class="aside-section validate-form"
    >
      <div class="flex-fill pt-4 pb-6">
        <div class="pb-2">
          <h2 class="fw-bold">
            Forgot<br />
            Your Password?
          </h2>
        </div>
        <div
          class="alert alert-danger d-flex fade show"
          *ngIf="errorMsg"
          role="alert"
        >
          <div><i class="fa fa-warning me-2" aria-hidden="true"></i></div>
          <div class="flex-fill">
            {{ errorMsg }}
          </div>
        </div>
        <div
          class="alert alert-success d-flex fade show"
          *ngIf="successMsg"
          role="alert"
        >
          <div>
            <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>
          </div>
          <div class="flex-fill">
            {{ successMsg }}
          </div>
        </div>

        <div class="card card-btn is-active mt-3">
          <div class="card-body" (click)="resetField(fields.email)">
            <div class="notification-each d-flex">
              <div class="notifyimg bg-dark rounded">
                <i class="fa fa-envelope text-white"></i>
              </div>
              <div class="ps-3">
                <strong class="text-dark fs-16">{{
                  messages.resetViaEmail
                }}</strong>
                <div class="text-gray">
                  {{ messages.resetPasswordLink }}
                </div>
              </div>
            </div>

            <div class="card-btn__input mt-4">
              <input
                class="input100 ps-4"
                type="text"
                name="email"
                placeholder="Email Address"
                id="emailAddress"
                formControlName="emailId"
              />
            </div>
            <div
              *ngIf="form.get('emailId').touched && form.get('emailId').invalid"
              class="errors mt-1"
            >
              <div *ngIf="form.get('emailId').hasError('required')">
                * {{ messages.EMAIL_REQUIRED1 }}
              </div>
              <div *ngIf="form.get('emailId').hasError('email')">
                * {{ messages.EMAIL_VALID }}
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-2 card-btn">
          <div class="card-body" (click)="resetField(fields.mobile)">
            <div class="notification-each d-flex">
              <div class="notifyimg bg-dark rounded">
                <i class="fa fa-phone text-white"></i>
              </div>
              <div class="ps-3">
                <strong class="text-dark fs-16">{{ messages.viaSms }}</strong>
                <div class="text-gray">
                  {{ messages.resetPasswordLink }}
                </div>
              </div>
            </div>

            <div class="card-btn__input mt-4">
              <input
                class="input100 ps-4"
                type="text"
                name="mobileNumber"
                placeholder="Mobile Number"
                id="mobileNumber"
                formControlName="mobileNumber"
                mask="(XXX) XXX-XXXX"
              />
            </div>
            <div
              *ngIf="
                form.get('mobileNumber').touched &&
                form.get('mobileNumber').invalid
              "
              class="errors mt-1"
            >
              <div *ngIf="form.get('mobileNumber').hasError('required')">
                * {{ messages.mobileRequired }}
              </div>
              <div *ngIf="form.get('mobileNumber').hasError('pattern')">
                * {{ messages.mobileValid }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 py-2">
            <a
              [routerLink]="['/login']"
              class="d-inline-block fw-semibold mt-2"
            >
              <i class="fa fa-arrow-left me-1"></i> Sign In</a
            >
          </div>
          <div class="col-6 text-end py-2">
            <button
              type="submit"
              class="btn btn-dark py-2 px-6"
              [disabled]="!form.valid"
            >
              Send Link
            </button>
          </div>
        </div>
      </div>
    </form>
    <div>
      <hr class="mt-0" />
      <div class="text-center fs-12 op-0-5 text-sm-end pb-3">
        <app-version></app-version>
      </div>
    </div>
  </div>
</div>
