<div
  class="toast m-3"
  [ngClass]="{
    show: toasterData.showToster == true,
    'toast-danger': toasterData.type === 'error',
    'toast-warning': toasterData.type === 'warning',
    'toast-success': toasterData.type === 'success'
  }"
  style="z-index: 9999"
>
  <div class="d-flex text-center">
    <div class="py-3 ps-4 fs-18">
      <i
        class="fa"
        [ngClass]="{
          show: toasterData.showToster == true,
          'fa-shield': toasterData.type === 'error',
          'fa-info-circle': toasterData.type === 'warning',
          'fa-check-circle-o': toasterData.type === 'success'
        }"
      ></i>
    </div>
    <div class="toast-body fs-15">
      {{ toasterData.message }}
    </div>
    <div class="py-4 pe-3">
      <button
        type="button"
        class="btn-close fs-18"
        (click)="hideToster()"
        data-dismiss="toast"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
