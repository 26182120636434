import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ICustomLogEvents, ISessionDetails } from 'src/app/interfaces/customLogEvents';
import { EventService } from 'src/app/services/event.service';
import { LoggingService } from 'src/app/services/logging.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hold-patients',
  templateUrl: './hold-patients.component.html',
  styleUrls: ['./hold-patients.component.scss']
})
export class HoldPatientsComponent {
  selectedPractice: any;
  userInfo: any;
  chartConfig: any = { apiUrl: 'Patient/summary/holdPatients', title: '', dataLabels: true, showInfo: false };
  holdPatients: any = [];
  chartColors: String[] = ["#76ADDB", "#C8DB70", "#0B314F", "#999999", "#d96716"];
  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = 'Dashboard - Web';


  constructor(private httpClient: HttpClient, 
    private loggingService: LoggingService,
    private eventService: EventService) { }
  ngOnInit() {
    this.loggingService.eventService.selectedPracticeSubscription().subscribe((practice) => {
      this.selectedPractice = practice;
    });
    this.loggingService.storeService.userInfoSubscription().subscribe((info) => {
      this.userInfo = info;
    });
    this.sessionDetails = this.loggingService.getSessionDetails(this.userInfo, this.selectedPractice);
    this.customLog = this.loggingService.createCustomLog('Patient On Hold API', '', 'INFO', this.sessionDetails, '/api/Patient/summary/holdPatients', 'GET', {});

    this.httpClient.get(`${environment.baseApiUrl}/api/Patient/summary/holdPatients`).subscribe({
      next: (response: any) => {
        const gridData: any = [];
        let sum: number = 0;
        Object.keys(response).forEach((key: string) => {
          if (response[key] && typeof (response[key]) === 'number') {
            sum += response[key];
          } else {
            this.eventService.openToaster({
              showToster: true,
              message: `Hold Patients Data is not coming in correct format from backend.`,
              type: 'danger',
            });
            return;
          }
        });
        Object.keys(response).forEach((key: string, index: number) => {
          gridData.push({ key, value: response[key], percent: parseFloat((response[key] / sum * 100).toFixed(0)), color: this.chartColors[index] });
        });
        this.holdPatients = gridData;
        this.customLog.message = 'API Call Successful';
        this.loggingService.trackEvent(this.customEvent, this.customLog);
      },
      error: (error: any) => {
        this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
      }
    })
  }
}
