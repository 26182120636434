/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NetworkUpsertModel { 
    networkName?: string;
    createdBy?: number;
    createdOn?: Date;
    states: string;
    city?: string;
    address?: string;
    zipCode?: string;
    latitude?: number;
    longitude?: number;
    id?: number;
    insertFlag?: boolean;
}