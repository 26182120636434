/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PracticeUpsertModel { 
    practiceName: string;
    groupType: string;
    address?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    latitude?: number;
    longitude?: number;
    networkId?: number;
    npi?: string;
    programType?: string;
    id?: number;
    mraOfficeName?: Array<string>;
    insertFlag?: boolean;
}