/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';

export interface AdmissionFilterModel { 
    formDateTime?: Date;
    endDateTime?: Date;
    currentPage?: number;
    pageSize?: number;
    sortBy?: string;
    sortOrderBy?: SortOrder;
    caseCategory?: Array<string>;
    diagnosis?: string;
    facilityName?: string;
    stage?: Array<string>;
    searchKey?: string;
    nephrologist?: string;
    dischargeDisposition?: string;
}