<div class="card mb-5 p-5 text-center">
    <div class="mb-4">
      <img
        class="wrap-figure__img"
        src="../../../assets/images/no-report.png"
        alt="No data found"
      />
    </div>
    <h2 class="fw-bold mb-4">No report found</h2>
    <div>
      <a
        href="javascript: void(0);"
        (click)="reloadPage()"
        class="btn btn-outline-danger mx-1 px-5"
        ><i class="fa fa-mail-reply me-1"></i> Retry</a
      >
    </div>
  </div>
  