<div class="side-app">
  <div class="sub-header">
    <div class="main-container container">
      <div class="d-flex justify-content-between align-items-center mt-3">
        <div>
          <h1 class="page-title m-0">Patient</h1>
          <ol class="breadcrumb flex-nowrap">
            <li class="breadcrumb-item">
              <a routerLink="/">HOME</a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="/account">ACCOUNT</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page" id="networkList">
              PATIENT
            </li>
          </ol>
        </div>
        <div>
          <button class="btn btn-outline-secondary" data-bs-target="#providerexportModel" data-bs-toggle="modal" >
            <i class="fa fa-download me-1"></i> Export
          </button>
        </div>
      </div>

      <div class="card mt-1 mb-5 p-2">
        <div class="table-responsive">
          <table id="data-table" class="table text-nowrap m-0 table-bordered table-striped">
            <thead class="table-head" *ngIf="list.length >0 && !showLoading">
              <tr class="text-secondary bg-gray-200">
                <th>
                  MEMBER ID
                </th>
                <th>
                  PATIENT NAME
                </th>
                <th class="text-center">
                  NEPHROLOGIST
                </th>
                <th class="text-center">
                  DOB (AGE)
                </th>
                <th class="text-center">
                  Attributed
                </th>
                <th class="text-center">
                  Engaged
                </th>
                <th class="text-center">
                  Assessed
                </th>
                <th class="text-center">
                  PMPM Amount
                </th>
                <th class="text-center">
                  Current STATUS
                </th>
              </tr>
            </thead>
            <tbody *ngIf="showLoading" class="table-body">
              <tr *ngFor="let i of getIterations()">
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
                <td><span class="skeleton-loader my-1"></span></td>
              </tr>
            </tbody>
            <tbody *ngIf="!showLoading" class="table-body">
              <tr *ngFor="let item of list">
                <td>
                  <div class="d-flex align-items-center">
                    <div class="user-details ms-2">
                      <h6 class="mb-0">{{ item.patient?.enrollmentNo }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <i data-bs-toggle="tooltip" data-bs-placement="top"
                      [title]="item.isRegistered ? 'Registered on MySomatus' : 'Not Registered on MySomatus'" class="fa"
                      [ngClass]="!item.isRegistered ? 'fa-times-circle text-danger' : 'fa-check-circle text-success'"></i>
                    <div class="user-details ms-2">
                      <h6 class="mb-0">{{ item.patient?.name }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="user-details ms-2">
                      {{ item.nephrologistName ? item.nephrologistName : '-' }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  {{ getFormatDate(item.dob)
                  }}<strong class="fw-semibold"> ({{ getAge(item.dob) }})</strong>
                </td>
                <td class="text-center">
                  {{ item.attributed? 1 : 0 }}
                  <strong class="fw-semibold"></strong>
                </td>
                <td class="text-center">
                  {{ item.engaged? 1 : 0 }}
                  <strong class="fw-semibold"></strong>
                </td>
                <td class="text-center">
                  {{ item.assessed? 1 : 0 }}
                  <strong class="fw-semibold"></strong>
                </td>
                <td class="text-center"> $
                  {{ item.pmpmAmount }}
                </td>
                <td class="text-center">{{ item.status }}</td>
              </tr>
            </tbody>
          </table>
          <ng-container *ngIf="!showLoading && list.length > 0">
            <div class="me-5 mt-4 mb-2 d-inline-block">
              <i class="fa fa-check-circle text-success fs-14"></i> Registered on MySomatus
            </div>
            <div class="me-5 mt-4 mb-2 d-inline-block">
              <i class="fa fa-times-circle text-danger fs-14"></i> Not Registered on MySomatus
            </div>
          </ng-container>
          <app-no-records-found *ngIf="list.length == 0 && !showLoading"></app-no-records-found>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- PROVIDER EXPORT MODAL -->
<div class="modal fade" id="providerexportModel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-demo">
      <div class="modal-header bg-gray-200">
        <h4 class="modal-title fw-semibold">Export Payment Patient Report</h4>
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-4" *ngIf="!exportStatus">
          <label for="stage" class="mb-1">File Name</label>
          <div class="input-group mb-4">
            <input type="text" name="text" placeholder="Enter File Name" id="searchPatient" class="form-control w-50"
              [value]="fileNameExport" (input)="fileNameExport = $any($event.target).value"
              (keypress)="acceptEnter($event)" />
            <input type="text" name="text" value=".xlsx" class="form-control" readonly />
            <button class="btn btn-secondary" type="button" [disabled]="!fileNameExport || disabledExport"
              (click)="submitExport()">
              Export
            </button>
          </div>
        </div>

        <div class="mb-4" *ngIf="exportStatus">
          <div class="h-6 mb-2 fs-16" *ngIf="exportStatus === 'waitingForStatus'">
            Your export request is being processed now. Please check notification bell icon to download.
          </div>
          <div class="progress h-6 mb-2 fs-16" *ngIf="exportStatus === 'submitted'">
            <div class="progress-bar progress-bar-striped progress-bar-animated w-100" role="progressbar"
              aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
              Submitted for Review
            </div>
          </div>

          <div class="progress h-6 mb-2 fs-16" *ngIf="exportStatus === 'inprogress'">
            <div class="progress-bar bg-warning progress-bar-striped progress-bar-animated w-100" role="progressbar"
              aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
              Sending Patient Payment export request, please wait...
            </div>
          </div>

          <div class="progress h-6 mb-2 fs-16" *ngIf="exportStatus === 'approved'">
            <div class="progress-bar bg-success progress-bar-striped progress-bar-animated w-100" role="progressbar"
              aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
              Approved
            </div>
          </div>

          <div class="progress h-6 mb-2 fs-16" *ngIf="exportStatus === 'rejected'">
            <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated w-100" role="progressbar"
              aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
              Rejected
            </div>
          </div>
          <div class="progress h-6 mb-2 fs-16" *ngIf="exportStatus === 'error'">
            <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated w-100" role="progressbar"
              aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
              Error
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>