/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserActivityLogEditModel { 
    sessionId?: string;
    userId?: string;
    userName?: string;
    browser?: string;
    clientIp?: string;
    loginAttemptCount?: number;
    loggedInAt?: Date;
    loggedOutAt?: Date;
    singalRConnectionId?: string;
    deviceToken?: string;
    isNew?: boolean;
}