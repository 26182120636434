export enum FileTypes {
    Audio = 1,
    Video = 2,
    Image = 3,
    Doc = 4,
    Pdf = 5,
    Excel = 6,
    BroadCast = 7,
    WebLink = 8,
    Ppt = 9,
    Txt = 10,
    Zip = 11,
    Html = 12
}