/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DocumentDocumentBody { 
    id?: number;
    file?: Blob;
    fileName?: string;
    downloadURL?: string;
    description: string;
    title: string;
    folder?: string;
    isGlobal?: boolean;
    isDeleted?: boolean;
    practiceIds?: Array<number>;
    tags?: Array<string>;
}