/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PatientFilterModel } from '../model/patientFilterModel';
import { PatientLatLongRequestModel } from '../model/patientLatLongRequestModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PatientService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param enrollmentNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientCaremembersEnrollmentNumberGet(enrollmentNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientCaremembersEnrollmentNumberGet(enrollmentNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientCaremembersEnrollmentNumberGet(enrollmentNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientCaremembersEnrollmentNumberGet(enrollmentNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enrollmentNumber === null || enrollmentNumber === undefined) {
            throw new Error('Required parameter enrollmentNumber was null or undefined when calling apiPatientCaremembersEnrollmentNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/caremembers/${encodeURIComponent(String(enrollmentNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientActivityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientCareplanDownloadPatientActivityIdGet(patientActivityId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientCareplanDownloadPatientActivityIdGet(patientActivityId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientCareplanDownloadPatientActivityIdGet(patientActivityId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientCareplanDownloadPatientActivityIdGet(patientActivityId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientActivityId === null || patientActivityId === undefined) {
            throw new Error('Required parameter patientActivityId was null or undefined when calling apiPatientCareplanDownloadPatientActivityIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/careplan/download/${encodeURIComponent(String(patientActivityId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param enrollmentNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientCareplansEnrollmentNumberGet(enrollmentNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientCareplansEnrollmentNumberGet(enrollmentNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientCareplansEnrollmentNumberGet(enrollmentNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientCareplansEnrollmentNumberGet(enrollmentNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enrollmentNumber === null || enrollmentNumber === undefined) {
            throw new Error('Required parameter enrollmentNumber was null or undefined when calling apiPatientCareplansEnrollmentNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/careplans/${encodeURIComponent(String(enrollmentNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param enrollmentNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientContactsEnrollmentNumberGet(enrollmentNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientContactsEnrollmentNumberGet(enrollmentNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientContactsEnrollmentNumberGet(enrollmentNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientContactsEnrollmentNumberGet(enrollmentNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enrollmentNumber === null || enrollmentNumber === undefined) {
            throw new Error('Required parameter enrollmentNumber was null or undefined when calling apiPatientContactsEnrollmentNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/contacts/${encodeURIComponent(String(enrollmentNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientCountGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientCountGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientCountGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientCountGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientCountLocationGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientCountLocationGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientCountLocationGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientCountLocationGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/count/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param enrollmentNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientDetailEnrollmentNumberGet(enrollmentNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientDetailEnrollmentNumberGet(enrollmentNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientDetailEnrollmentNumberGet(enrollmentNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientDetailEnrollmentNumberGet(enrollmentNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enrollmentNumber === null || enrollmentNumber === undefined) {
            throw new Error('Required parameter enrollmentNumber was null or undefined when calling apiPatientDetailEnrollmentNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/detail/${encodeURIComponent(String(enrollmentNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientLatlongPost(body?: PatientLatLongRequestModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientLatlongPost(body?: PatientLatLongRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientLatlongPost(body?: PatientLatLongRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientLatlongPost(body?: PatientLatLongRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Patient/latlong`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientListPost(body?: PatientFilterModel, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientListPost(body?: PatientFilterModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientListPost(body?: PatientFilterModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientListPost(body?: PatientFilterModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Patient/list`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param enrollmentNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientMedicationEnrollmentNumberGet(enrollmentNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientMedicationEnrollmentNumberGet(enrollmentNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientMedicationEnrollmentNumberGet(enrollmentNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientMedicationEnrollmentNumberGet(enrollmentNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enrollmentNumber === null || enrollmentNumber === undefined) {
            throw new Error('Required parameter enrollmentNumber was null or undefined when calling apiPatientMedicationEnrollmentNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/medication/${encodeURIComponent(String(enrollmentNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param enrollmentNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientProfileImageEnrollmentNumberGet(enrollmentNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientProfileImageEnrollmentNumberGet(enrollmentNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientProfileImageEnrollmentNumberGet(enrollmentNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientProfileImageEnrollmentNumberGet(enrollmentNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enrollmentNumber === null || enrollmentNumber === undefined) {
            throw new Error('Required parameter enrollmentNumber was null or undefined when calling apiPatientProfileImageEnrollmentNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/profile-image/${encodeURIComponent(String(enrollmentNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param searchString 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSearchSearchStringGet(searchString: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSearchSearchStringGet(searchString: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSearchSearchStringGet(searchString: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSearchSearchStringGet(searchString: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (searchString === null || searchString === undefined) {
            throw new Error('Required parameter searchString was null or undefined when calling apiPatientSearchSearchStringGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/search/${encodeURIComponent(String(searchString))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param enrollmentNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientStatusEnrollmentNumberGet(enrollmentNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientStatusEnrollmentNumberGet(enrollmentNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientStatusEnrollmentNumberGet(enrollmentNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientStatusEnrollmentNumberGet(enrollmentNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enrollmentNumber === null || enrollmentNumber === undefined) {
            throw new Error('Required parameter enrollmentNumber was null or undefined when calling apiPatientStatusEnrollmentNumberGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/status/${encodeURIComponent(String(enrollmentNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryAgeGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryAgeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryAgeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryAgeGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/age`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryCareteammemberGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryCareteammemberGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryCareteammemberGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryCareteammemberGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/careteammember`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryChronicconditionsGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryChronicconditionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryChronicconditionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryChronicconditionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/chronicconditions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryDemeographicTypeGet(type: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryDemeographicTypeGet(type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryDemeographicTypeGet(type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryDemeographicTypeGet(type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiPatientSummaryDemeographicTypeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/demeographic/${encodeURIComponent(String(type))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param period 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryEngagementPeriodGet(period: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryEngagementPeriodGet(period: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryEngagementPeriodGet(period: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryEngagementPeriodGet(period: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling apiPatientSummaryEngagementPeriodGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/engagement/${encodeURIComponent(String(period))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryExportFilenameGet(filename: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryExportFilenameGet(filename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryExportFilenameGet(filename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryExportFilenameGet(filename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling apiPatientSummaryExportFilenameGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/export/${encodeURIComponent(String(filename))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryExportstatusGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryExportstatusGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryExportstatusGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryExportstatusGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/exportstatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryGenderGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryGenderGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryGenderGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryGenderGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/gender`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryHoldPatientsGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryHoldPatientsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryHoldPatientsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryHoldPatientsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/holdPatients`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryLobGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryLobGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryLobGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryLobGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/lob`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryPayerGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryPayerGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryPayerGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryPayerGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/payer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryProfileIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryProfileIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryProfileIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryProfileIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPatientSummaryProfileIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/profile/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryRiskcategoryGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryRiskcategoryGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryRiskcategoryGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryRiskcategoryGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/riskcategory`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param chartType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryStageChartTypeGet(chartType: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryStageChartTypeGet(chartType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryStageChartTypeGet(chartType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryStageChartTypeGet(chartType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chartType === null || chartType === undefined) {
            throw new Error('Required parameter chartType was null or undefined when calling apiPatientSummaryStageChartTypeGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/stage/${encodeURIComponent(String(chartType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPatientSummaryStageGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPatientSummaryStageGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPatientSummaryStageGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPatientSummaryStageGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Patient/summary/stage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
