import { Component } from "@angular/core";
declare const $: any;
@Component({
  selector: "app-terms-and-conditions",
  standalone: true,
  imports: [],
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent {
  ngOnInit(): void {
    $('.header').addClass('d-none');
    $('#back-to-top').addClass('d-none');
  }
  ngOnDestroy(): void {
    $('.header').removeClass('d-none');
    $('#back-to-top').removeClass('d-none');
  }
}
