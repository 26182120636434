<div class="aside-section__outer row mx-0">
  <div
    class="d-none d-md-flex wrap-figure100 col-md-6 col-lg-7 col-xl-8 px-0 bg-gray-300"
  >
    <div>
      <span class="light-theme-img">
        <img
          class="wrap-figure100__img"
          src="../../../assets/images/login.jpg"
          alt="More Lives, Better Lived."
        />
      </span>
      <span class="dark-theme-img">
        <img
          class="wrap-figure100__img"
          src="../../../assets/images/login-dark.jpg"
          alt="More Lives, Better Lived."
        />
      </span>
      <div class="px-4 py-2">{{ messages.COPYRIGHT_MESSAGE }}</div>
    </div>
  </div>
  <div class="col-md-6 col-lg-5 col-xl-4 px-xl-7 px-6 bg-white">
    <div>
      <div class="pt-7 pb-3">
        <img
          class="img-fluid"
          src="../../../assets/images/brand/renalliqconnect.png"
          alt="renallIQconnect"
        />
      </div>
      <hr class="mb-0" />
    </div>
    <form
      [formGroup]="resetForm"
      (ngSubmit)="onSubmit(resetForm)"
      novalidate
      class="aside-section validate-form"
    >
      <div class="flex-fill pt-4 pb-6">
        <div class="pb-3">
          <h2 class="fw-bold">
            Reset<br />
            New Password?
          </h2>
        </div>
        <label class="form-label mt-3" for="emailAddress">New Password</label>
        <div class="wrap-input100 input-password">
          <input
            class="input100"
            type="password"
            name="pass"
            placeholder="New Password"
            id="password"
            formControlName="password"
          />
          <span class="symbol-input100">
            <i class="zmdi zmdi-lock" aria-hidden="true"></i>
          </span>
          <i class="fe fe-eye input-password__icon"></i>
          <i class="fe fe-eye-off input-password__icon"></i>
        </div>
        <div
          *ngIf="
            resetForm.get('password').touched &&
            resetForm.get('password').invalid
          "
          class="errors"
        >
          <div *ngIf="resetForm.get('password').hasError('required')">
            * {{ messages.PASSWORD_REQUIRED }}
          </div>
          <div *ngIf="resetForm.get('password').hasError('pattern')">
            * {{ messages.PSW_VALIDITY }}
          </div>
        </div>
        <label class="form-label mt-3" for="password">Confirm Password</label>
        <div
          class="wrap-input100 input-password"
          data-bs-validate="Password is required"
        >
          <input
            id="confirmPassword"
            class="input100"
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            id="password2"
            formControlName="confirmPassword"
          />
          <span class="focus-input100"></span>
          <span class="symbol-input100">
            <i class="zmdi zmdi-lock" aria-hidden="true"></i>
          </span>
          <i class="fe fe-eye input-password__icon"></i>
          <i class="fe fe-eye-off input-password__icon"></i>
        </div>
        <div
          *ngIf="
            resetForm.get('confirmPassword').touched &&
            resetForm.get('confirmPassword').invalid
          "
          class="errors"
        >
          <div *ngIf="resetForm.get('confirmPassword').hasError('required')">
            * {{ messages.confirmPasswordRequired }}
          </div>
          <div *ngIf="resetForm.get('confirmPassword').hasError('pattern')">
            * {{ messages.confirmPasswordValid }}
          </div>
        </div>
        <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
          Error! {{ errorMsg }}
        </div>

        <div class="row">
          <div class="col-6 py-2">
            <a [routerLink]="['/login']" class="d-inline-block fw-semibold mt-2"
              ><i class="fa fa-mail-reply me-1"></i> Sign In</a
            >
          </div>
          <div class="col-6 text-end py-2">
            <button
              type="submit"
              class="btn btn-dark py-2 px-6"
              [disabled]="!resetForm.valid"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
    <div>
      <hr class="mt-0" />
      <div class="text-center fs-12 op-0-5 text-sm-end pb-3">
        <app-version></app-version>
      </div>
    </div>
  </div>
</div>
