import { Component } from '@angular/core';
import { DashboardService, PatientService } from 'src/app/api-client';
import { DataCardInput } from 'src/app/interfaces/data-card';
import { ProgressBarChartWidgetInput } from 'src/app/interfaces/progress-bar-chart-widget';
import { Messages } from 'src/app/shared/common-constants/messages';
import { DescriptionCardInput } from './summary-interfaces/description-card';
import { ICustomLogEvents, ISessionDetails } from 'src/app/interfaces/customLogEvents';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  iterationCount: number = 10;
  selectedPractice: any;
  userInfo: any;
  configJson: any = {};
  patients: DataCardInput = {
    iconClass: 'fa fa-user-circle',
    cardTitle: Messages.attributed,
    count: '-',
    percentile: null,
    performance: 'up',
    routeName: 'patients'
  };
  admissions: DataCardInput = {
    iconClass: 'fa-target',
    cardTitle: Messages.admissions,
    count: '-',
    percentile: null,
    performance: 'down',
    routeName: 'admission'
  };
  assessedPatients: DataCardInput = {
    iconClass: 'fa-people',
   cardTitle: Messages.assessedPatients,
    count: '-',
    percentile: null,
    performance: 'up',
  //   routeName: 'patients'
  };

  engagedPatients: DataCardInput = {
    iconClass: 'fa-people',
    cardTitle: Messages.engagedPatients,
    count: '-',
    percentile: null,
    performance: 'up',
 //   routeName: 'patients'
  };
  admissionRecent: DataCardInput = {
    iconClass: 'fa-people',
    cardTitle: Messages.admissions7Days,
    count: '-',
    percentile: null,
    performance: 'up',
  };
  patientInsight: DescriptionCardInput = {
    redirectTo: '/reports/insight',
    iconClass: 'fa-eye',
    cardTitle: Messages.patientInsight,
    description: Messages.patientPopullation,
  };
  qualityMatrics: DescriptionCardInput = {
    redirectTo: '/reports/qualityMetrics',
    iconClass: 'fa-briefcase',
    cardTitle: Messages.clinicalText1,
    description: Messages.practiceCurrentPerformance,
  };
  riskStratification: DescriptionCardInput = {
    redirectTo: '/reports/riskAnalysis',
    iconClass: 'fa fa-user-circle',
    cardTitle: Messages.riskText1,
    description: Messages.patientInsightText2,
  };
  providerMetrics: DescriptionCardInput = {
    redirectTo: '/reports/providerMetrics',
    iconClass: 'fa fa-briefcase',
    cardTitle: Messages.providerTitle,
    description: Messages.providerText,
  };
  patientByRiskCategor: ProgressBarChartWidgetInput = {
    title: Messages.riskText2,
    apiUrl: 'Patient/summary/riskcategory',
  };
  patientByComorbidity: ProgressBarChartWidgetInput = {
    title: Messages.patientText1,
    apiUrl: 'Patient/summary/chronicconditions',
  };
  patientByAgeGroup: ProgressBarChartWidgetInput = {
    title: Messages.patientText2,
    apiUrl: 'Patient/summary/age',
  };

  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = 'Dashboard - Web';

  constructor(private _patientService: PatientService,private dashboardService : DashboardService,
    private loggingService: LoggingService) {}
  ngOnInit() {
    this.loggingService.eventService.selectedPracticeSubscription().subscribe((practice) => {
      this.selectedPractice = practice;
    });
    this.loggingService.storeService.userInfoSubscription().subscribe((info) => {
      this.userInfo = info;
    });
    this.sessionDetails = this.loggingService.getSessionDetails(this.userInfo, this.selectedPractice);
    this.loggingService.logEventForComponentRendering(this.customEvent, this.sessionDetails);
    this.getDashboardConfiguration(); 
    this._patientService.apiPatientCountGet().subscribe((response: any) => {
      this.admissions = {
        ...this.admissions,
        ...response.totalAdmission,
      };
      this.patients = {
        ...this.patients,
        ...response.totalPatient,
      };
      this.engagedPatients = {
        ...this.engagedPatients,
        ...response.totalEngagedPatient,
      };
      this.assessedPatients ={
        ...this.assessedPatients,
        ... response.totalAssessedPatient,
      }   
      this.admissionRecent = {
        ...this.admissionRecent,
        ...response.totalRecentAdmission,
      };
    },
    (error: any) => {
      this.customLog.level = 'ERROR';
      this.customLog.message = error;
      this.loggingService.trackEvent(this.customEvent, this.customLog);
      this.loggingService.logException(error);
    });
  }
  getDashboardConfiguration(){
    this.customLog = this.loggingService.createCustomLog('Dashboard Configuration API', '', 'INFO', this.sessionDetails, '/api/Dashboard/config', 'GET', {});
    this.dashboardService.apiDashboardConfigGet().subscribe({
      next:(response:any) => {
        this.configJson = JSON.parse(response.dashboardConfigJSON);
        this.customLog.message = 'API Call Successful';
        this.loggingService.trackEvent(this.customEvent, this.customLog);
      },
      error:(error: any) => {
        this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
      }
    });
  }
}
