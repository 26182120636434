export const environment = {
  production: '_@_production_@_',
  baseApiUrl: '_@_baseApiUrl_@_',
  appUrl: '_@_appUrl_@_',
  pageSize: 20,
  AzureConfiguration: '_@_AzureConfiguration_@_',
  AzureConfigurationLabel: '_@_AzureConfigurationLabel_@_',
  googleMapsApiKey: '_@_googleMapsApiKey_@_',
  gTag_Id: '_@_gTag_Id_@_',
  connectionString: '_@_connectionString_@_'
};