import { Injectable } from '@angular/core';
import { AppConfigurationClient, ConfigurationSetting } from "@azure/app-configuration";
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class AzureConfigurationService {
  private configClient: AppConfigurationClient;
  private configSettings: any = {};
  private label: string = '';
  public clientId!: string;
  public clientSecret!: string;
  public grantType!: string;
  public scopes!: string;
  constructor() {
    const connectionString = environment.AzureConfiguration;
    this.label = environment.AzureConfigurationLabel;
    this.configClient = new AppConfigurationClient(connectionString);
    this.initializeAzureVariables();
  }
 
  async loadConfigurationsWithLabel(label : any) {
    const settings: AsyncIterableIterator<ConfigurationSetting> = this.configClient.listConfigurationSettings({
      labelFilter:label
    });

    for await (const setting of settings) {
        this.configSettings[setting.key] = setting.value;
    }
  }
 
  async getConfigSetting(key: string) {
    await this.loadConfigurationsWithLabel(this.label);
    return this.configSettings[key];
  }

  async initializeAzureVariables() {
    this.clientId = await this.getConfigSetting('identity:clientId');
    this.clientSecret = await this.getConfigSetting('identity:clientSecret');
    this.grantType = await this.getConfigSetting('identity:grantType');
    this.scopes = await this.getConfigSetting('identity:scopes');
  }
}
