<div class="d-flex" *ngIf="showLoading">
  <div class="skeleton-loader__media" [ngClass]="{'mt-2 me-5' : chartConfig.showInfo, 'm-auto': !chartConfig.showInfo }">
    <span class="skeleton-loader"></span>
  </div>
  <div class="flex-fill" *ngIf="chartConfig.showInfo">
    <div class="form-row my-4">
      <div class="col-2"><span class="skeleton-loader"></span></div>
      <div class="col-10">
        <span class="skeleton-loader"></span>
      </div>
    </div>
    <div class="form-row my-4">
      <div class="col-2"><span class="skeleton-loader"></span></div>
      <div class="col-10">
        <span class="skeleton-loader"></span>
      </div>
    </div>
    <div class="form-row my-4">
      <div class="col-2"><span class="skeleton-loader"></span></div>
      <div class="col-10">
        <span class="skeleton-loader"></span>
      </div>
    </div>
    <div class="form-row my-4">
      <div class="col-2"><span class="skeleton-loader"></span></div>
      <div class="col-10">
        <span class="skeleton-loader"></span>
      </div>
    </div>
  </div>
</div>

<highcharts-chart
    *ngIf="!errorMessage"
    style="width: 100%; height: 100%; display: block"
    [Highcharts]="Highcharts"
    [options]="options"
>
</highcharts-chart>
<div *ngIf="errorMessage">
    <div class="alert alert-danger d-flex fade show"
      role="alert">
      <div><i class="fa fa-warning me-2" aria-hidden="true"></i></div>
      <div class="flex-fill">
        {{ errorMessage }}
      </div>
    </div>
</div>