export const Messages = {
  admissions: 'Utilization Last 90 Days',
  admissions7Days: 'Last 7 Days',
  attributed: 'Attributed',
  checkSpamMessage: "If you don't see it, check your spam.",
  clinicalText1: 'Clinical Quality Metrics',
  confirmPasswordRequired: 'Confirm Password is required',
  confirmPasswordValid: 'Confirm Password is not valid',
  continue: 'to Continue',
  COPYRIGHT_MESSAGE: '© 2024 Somatus. All Rights Reserved',
  engagedPatients: 'Engaged',
  assessedPatients: 'Assessed',
  emailMessage:
    "We've sent an email message containing a link to Reset your password to your inbox.",
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_VALID: 'Enter the valid email',
  EMAIL_REQUIRED1: 'Email Address is required',
  errorFetchingData: 'Error in fetching data.',
  errorPasswordsNotMatched: 'Password and Confirm Password not matched.',
  errorNotAuthorized: 'Not authorized request.',
  error404Header: '404 Error Page Not Found',
  error404Body: 'We couldn′t find the page you are looking for.',
  errorPractice:
    'Practice is not assigned to this user, Please contact RenelIQ team.',
  metricId: 'Metric id from route is not found.',
  mobileRequired: 'Mobile Number is required',
  mobileValid: 'Enter the valid Mobile Number',
  numberOfAttempts: 'Exhausted the number of account verification attempts',
  invalidUserNameOrPassWord: 'invalid_username_or_password',
  noData: 'No data found!',
  noPatientFound: 'No patient found!',
  noRecords: 'No Record Found.',
  PRODUCTNAME: 'RenalIQ Connect',
  PSW_VALIDITY: 'Password is not valid',
  PASSWORD_REQUIRED: 'Password is required',
  patientInsight: 'Patients Insight',
  patientInsightText2:
    'Insight of Patient Risk Stratification for the patient associated with Somatus',
  patientPopullation:
    'Insight of your patient popullation, including trend & performance comparision.',
  practiceCurrentPerformance:
    'Practice current performance on clinical quality metrics',
  patientText1: 'Patients by Primary Chronic Conditions',
  patientText2: 'Patients by Age Group',
  resetViaEmail: 'Reset via Email',
  resetPasswordLink: 'We will send a link to reset your password',
  riskText1: 'Risk Stratification',
  riskText2: 'Patients by Risk Category',
  providerTitle: 'Provider Level Metrics',
  providerText: 'Individual provider performance on clinical quality metrics',
  sendTo: 'Code was sent to',
  textMessage:
    "We've sent a text message containing a link to Reset your password.",
  viaSms: 'Reset via SMS',
  verificationCodeText: 'Enter Verification Code',
  verificationCodeRequired: 'Verification code is required',
  verificationCodeResend: 'Resend verification code',
  verificationCodeSuccessfully: 'Verification code sent successfully.',
  WELCOME_TEXT: 'Welcome to',
};
