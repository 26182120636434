import { Injectable } from '@angular/core';
import { AppInsightService } from 'src/app/services/app-insight.service';
import { EventService } from 'src/app/services/event.service';
import { StoreService } from 'src/app/services/store.service';
import { ICustomLogEvents, ISessionDetails } from '../interfaces/customLogEvents';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  userRoles: { [key: number]: string } = {
    1: 'System Admin',
    2: 'Document Manager',
    3: 'Somatus User',
    4: 'Practice User'
  };

  constructor(
    public appInsights: AppInsightService,
    public storeService: StoreService,
    public eventService: EventService
  ) { }

  getSessionDetails(userInfo: any, selectedPractice: any): ISessionDetails {
    let sessionDetails: ISessionDetails;
    sessionDetails = {
      userEmail: userInfo.email,
      userName: userInfo.fullName,
      userId: userInfo.userLoginId,
      practiceId: selectedPractice.practiceId
    };
    if (userInfo && Array.isArray(userInfo.roles) && userInfo.roles.length > 0) {
      const role = userInfo.roles[0];
      if (this.userRoles[role] !== undefined) {
        sessionDetails.userRole = this.userRoles[role];
      }
    }
    return sessionDetails;
  }

  createCustomLog(event: string, message: string, level: string, sessionDetails?: ISessionDetails, endpoint?: string, httpMethod?: string, query?: object): ICustomLogEvents {
    return {
      level,
      event,
      message,
      sessionDetails,
      timestamp: new Date().toString(),
      endpoint,
      httpMethod,
      query
    };
  }

  logEventForComponentRendering(customEvent: string, sessionDetails?: ISessionDetails) {
    var hash = window.location.hash;
    hash = hash.startsWith('#') ? hash.substring(2) : hash;
    hash = hash.replace(/\//g, ' -> ');
    this.appInsights.trackPageView(hash, window.location.href, sessionDetails);
    const customLog = this.createCustomLog('Component Loading', 'Component Rendered Successfully', 'INFO', sessionDetails);
    this.trackEvent(customEvent, customLog);
  }

  trackEvent(event: string, log?: ICustomLogEvents): void {
    this.appInsights.trackEvent(event, log as any);
  }

  logException(error: any): void {
    this.appInsights.logException(error);
  }
}
