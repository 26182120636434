/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ContractFilter { 
    programType?: string;
    contractType?: string;
    mraFlag?: boolean;
    networkId?: number;
    pmpmAssessedOrEngaged?: string;
    contractStartDate?: Date;
    contractEndDate?: Date;
    actualGoLiveDate?: Date;
    contractGroupType?: string;
    sortBy?: string;
    sortDirection?: string;
}