/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PhysicianReportViewModel { 
    physicianKey?: string;
    physicianNPI?: string;
    physicianName?: string;
    physicianType?: string;
    physicianPractice?: string;
    physicianNetwork?: string;
    periodId?: number;
    mbrs?: number;
    mbrsPercentage?: number;
    engagedPercentage?: number;
    notEngaged?: number;
    notEngagedPercentage?: number;
    assessedPercentage?: number;
    notAssessed?: number;
    notAssessedPercentage?: number;
    ckdAdmits?: number;
    ckdGroupAdmPercentage?: number;
    eskdAdmits?: number;
    eskdGroupAdmPercentage?: number;
    reAdmits?: number;
    reAdmitsPercentage?: number;
    notACEARBSAdherence?: number;
    notACEARBSAdherencePercentage?: number;
    notDiureticAdherence?: number;
    notDiureticAdherencePercentage?: number;
    notBPControl?: number;
    notBPControlPercentage?: number;
    notHBA1CControl?: number;
    notHBA1CControlPercentage?: number;
    notACPAdherence?: number;
    notACPAdherencePercentage?: number;
    notPeritonealDialysis?: number;
    notPeritonealDialysisPercentage?: number;
    unplannedDialysisStarts?: number;
    unplannedDialysisStartsPercentage?: number;
    cvC90?: number;
    cvC90Percentage?: number;
}