/**
 * WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddRecentDocumentRequestModel } from '../model/addRecentDocumentRequestModel';
import { DocumentFilterModel } from '../model/documentFilterModel';
import { DocumentListViewModel } from '../model/documentListViewModel';
import { RecentDocumentModel } from '../model/recentDocumentModel';
import { RecentDocumentViewModel } from '../model/recentDocumentViewModel';
import { TagListViewModel } from '../model/tagListViewModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentAddRecentdocumentPost(body?: AddRecentDocumentRequestModel, observe?: 'body', reportProgress?: boolean): Observable<RecentDocumentModel>;
    public apiDocumentAddRecentdocumentPost(body?: AddRecentDocumentRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecentDocumentModel>>;
    public apiDocumentAddRecentdocumentPost(body?: AddRecentDocumentRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecentDocumentModel>>;
    public apiDocumentAddRecentdocumentPost(body?: AddRecentDocumentRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RecentDocumentModel>('post',`${this.basePath}/api/Document/add/recentdocument`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDeleteIdPost(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiDocumentDeleteIdPost(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiDocumentDeleteIdPost(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiDocumentDeleteIdPost(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiDocumentDeleteIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Document/delete/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param fileName 
     * @param downloadURL 
     * @param description 
     * @param title 
     * @param folder 
     * @param isGlobal 
     * @param isDeleted 
     * @param practiceIds 
     * @param tags 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentPostForm(id?: number, file?: Blob, fileName?: string, downloadURL?: string, description?: string, title?: string, folder?: string, isGlobal?: boolean, isDeleted?: boolean, practiceIds?: Array<number>, tags?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiDocumentDocumentPostForm(id?: number, file?: Blob, fileName?: string, downloadURL?: string, description?: string, title?: string, folder?: string, isGlobal?: boolean, isDeleted?: boolean, practiceIds?: Array<number>, tags?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiDocumentDocumentPostForm(id?: number, file?: Blob, fileName?: string, downloadURL?: string, description?: string, title?: string, folder?: string, isGlobal?: boolean, isDeleted?: boolean, practiceIds?: Array<number>, tags?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiDocumentDocumentPostForm(id?: number, file?: Blob, fileName?: string, downloadURL?: string, description?: string, title?: string, folder?: string, isGlobal?: boolean, isDeleted?: boolean, practiceIds?: Array<number>, tags?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (id !== undefined) {
            formParams = formParams.append('Id', <any>id) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) as any || formParams;
        }
        if (fileName !== undefined) {
            formParams = formParams.append('FileName', <any>fileName) as any || formParams;
        }
        if (downloadURL !== undefined) {
            formParams = formParams.append('DownloadURL', <any>downloadURL) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) as any || formParams;
        }
        if (title !== undefined) {
            formParams = formParams.append('Title', <any>title) as any || formParams;
        }
        if (folder !== undefined) {
            formParams = formParams.append('Folder', <any>folder) as any || formParams;
        }
        if (isGlobal !== undefined) {
            formParams = formParams.append('IsGlobal', <any>isGlobal) as any || formParams;
        }
        if (isDeleted !== undefined) {
            formParams = formParams.append('IsDeleted', <any>isDeleted) as any || formParams;
        }
        if (practiceIds) {
            practiceIds.forEach((element) => {
                formParams = formParams.append('PracticeIds', <any>element) as any || formParams;
            })
        }
        if (tags) {
            tags.forEach((element) => {
                formParams = formParams.append('Tags', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Document/document`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDocumentsIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiDocumentDocumentsIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiDocumentDocumentsIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiDocumentDocumentsIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiDocumentDocumentsIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Document/documents/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentDownloadIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiDocumentDownloadIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiDocumentDownloadIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiDocumentDownloadIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiDocumentDownloadIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Document/download/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param folderName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentListFolderNameTagsGet(folderName: string, observe?: 'body', reportProgress?: boolean): Observable<TagListViewModel>;
    public apiDocumentListFolderNameTagsGet(folderName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TagListViewModel>>;
    public apiDocumentListFolderNameTagsGet(folderName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TagListViewModel>>;
    public apiDocumentListFolderNameTagsGet(folderName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (folderName === null || folderName === undefined) {
            throw new Error('Required parameter folderName was null or undefined when calling apiDocumentListFolderNameTagsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TagListViewModel>('get',`${this.basePath}/api/Document/list/${encodeURIComponent(String(folderName))}/tags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param isGlobal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentListFoldersIsGlobalGet(isGlobal: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiDocumentListFoldersIsGlobalGet(isGlobal: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiDocumentListFoldersIsGlobalGet(isGlobal: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiDocumentListFoldersIsGlobalGet(isGlobal: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (isGlobal === null || isGlobal === undefined) {
            throw new Error('Required parameter isGlobal was null or undefined when calling apiDocumentListFoldersIsGlobalGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Document/list/folders/${encodeURIComponent(String(isGlobal))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentListPost(body?: DocumentFilterModel, observe?: 'body', reportProgress?: boolean): Observable<DocumentListViewModel>;
    public apiDocumentListPost(body?: DocumentFilterModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentListViewModel>>;
    public apiDocumentListPost(body?: DocumentFilterModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentListViewModel>>;
    public apiDocumentListPost(body?: DocumentFilterModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentListViewModel>('post',`${this.basePath}/api/Document/list`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param isGlobal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentListTagsIsGlobalGet(isGlobal: boolean, observe?: 'body', reportProgress?: boolean): Observable<TagListViewModel>;
    public apiDocumentListTagsIsGlobalGet(isGlobal: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TagListViewModel>>;
    public apiDocumentListTagsIsGlobalGet(isGlobal: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TagListViewModel>>;
    public apiDocumentListTagsIsGlobalGet(isGlobal: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (isGlobal === null || isGlobal === undefined) {
            throw new Error('Required parameter isGlobal was null or undefined when calling apiDocumentListTagsIsGlobalGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TagListViewModel>('get',`${this.basePath}/api/Document/list/tags/${encodeURIComponent(String(isGlobal))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param count 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiDocumentRecentdocumentsGet(count?: number, observe?: 'body', reportProgress?: boolean): Observable<RecentDocumentViewModel>;
    public apiDocumentRecentdocumentsGet(count?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecentDocumentViewModel>>;
    public apiDocumentRecentdocumentsGet(count?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecentDocumentViewModel>>;
    public apiDocumentRecentdocumentsGet(count?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RecentDocumentViewModel>('get',`${this.basePath}/api/Document/recentdocuments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
