export class CommonConstants {

  public static CONNECT_TOKEN_KEY: string = "connect_tk";
  public static CONNECT_REFRESH_TOKEN_KEY: string = "refresh_tk";
  public static CONNECT_REFRESH_TOKEN_EXPIRY: string = "0.5";
  public static TWO_FA_KEY: string = "twoFA_login";
  public static USER_INFO_KEY: string = "user-info";
  public static EXPIRATION_TIME: string = "expiration_time";
  public static WEB_VERSION: string = "7.1";
  public static readonly CQM_INTRO: string = "CQM-Intro";
  public static readonly PROVIDER_METRICS_INTRO: string = "Provider-Metrics-Intro";
  public static readonly METRICS_COMPARISON_INTRO: string = "Metric-Comparison-Intro";
  public static readonly RISK_STRATIFICATION_INTRO: string = "Risk-Stratification-Intro";
  public static readonly REPORT_INTRO_EXPIRY: string = "30";
  
  constructor() { }
}
